import { ActionTypes } from './stories.types'

export const fetchStoriesStart = () => ({
	type: ActionTypes.FETCH_STORIES_START,
	payload: null,
})

export const fetchStoriesSuccess = (stories) => ({
	type: ActionTypes.FETCH_STORIES_SUCCESS,
	payload: stories,
})

export const fetchStoriesFailure = (error) => ({
	type: ActionTypes.FETCH_STORIES_FAILURE,
	payload: error,
})
