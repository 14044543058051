import { createSelector } from 'reselect'

export const storiesSelector = (state) => state.stories

export const storiesItemsSelector = createSelector([storiesSelector], (stories) => stories.items)

export const isFetchingStoriesSelector = createSelector(
	[storiesSelector],
	(stories) => stories.isFetching
)

export const storiesErrorSelector = createSelector([storiesSelector], (stories) => stories.error)
