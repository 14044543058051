import React, { useEffect } from 'react'

// styled components
import {
	StoriesSectionWrapper,
	ContentWrapper,
	SectionTitle,
	NoStoriesMessage,
} from './Stories.styles'

// components
import Loader from '../../../../components/Loader/Loader.component'
import PostCardCarousel from '../../../../components/PostCardCarousel/PostCardCarousel.component'

const StoriesSection = ({ items, isFetching, error, fetchStoriesStart }) => {
	useEffect(() => {
		fetchStoriesStart()
	}, [fetchStoriesStart])

	return (
		<StoriesSectionWrapper id="Stories">
			<SectionTitle>Stories</SectionTitle>
			<ContentWrapper>
				{error ? (
					<div>Failed to load story.</div>
				) : isFetching ? (
					<Loader />
				) : items && items.length > 0 ? (
					<PostCardCarousel posts={items} />
				) : (
					<NoStoriesMessage>No stories to show at this time.</NoStoriesMessage>
				)}
			</ContentWrapper>
		</StoriesSectionWrapper>
	)
}

export default StoriesSection
