import React from 'react'
import debounce from 'lodash.debounce'
import { useSwipeable } from 'react-swipeable'

// constants
import { socialMediaUrls } from '../../constants/urls'

// assets
import { ReactComponent as FacebookIcon } from '../../assets/svg/facebook.svg'
import { ReactComponent as InstagramIcon } from '../../assets/svg/instagram.svg'
import { ReactComponent as TwitterIcon } from '../../assets/svg/twitter.svg'

// styled components
import { DrawerWrapper, LinksWrapper, StyledLink, SocialMediaWrapper } from './Drawer.styles'

const Drawer = ({ isOpen, isBackgroundDark, closeDrawer }) => {
	const onSwipeRight = useSwipeable({
		onSwiping: debounce(({ dir, deltaX }) => {
			if (isOpen && dir === 'Right' && deltaX < -75) {
				closeDrawer()
			}
		}, 3),
	})

	const { FACEBOOK, INSTAGRAM, TWITTER } = socialMediaUrls

	return (
		<DrawerWrapper
			id="drawer"
			className={isOpen ? 'open' : ''}
			{...onSwipeRight}
			isBackgroundDark={isBackgroundDark}
		>
			<LinksWrapper isBackgroundDark={isBackgroundDark}>
				<StyledLink to="/#feed" onClick={closeDrawer}>
					{"Gretchen's Wall"}
				</StyledLink>
				<StyledLink to="/blog" onClick={closeDrawer}>
					Stories + Thoughts
				</StyledLink>
				<StyledLink to="/campaigns" onClick={closeDrawer}>
					Campaigns
				</StyledLink>
				<StyledLink to="/about" onClick={closeDrawer}>
					About
				</StyledLink>
			</LinksWrapper>
			<SocialMediaWrapper isBackgroundDark={isBackgroundDark}>
				<a href={FACEBOOK} target="_blank" rel="noopener noreferrer">
					<FacebookIcon className="icon fb" />
				</a>
				<a href={INSTAGRAM} target="_blank" rel="noopener noreferrer">
					<InstagramIcon className="icon insta" />
				</a>
				<a href={TWITTER} target="_blank" rel="noopener noreferrer">
					<TwitterIcon className="icon twitter" />
				</a>
			</SocialMediaWrapper>
		</DrawerWrapper>
	)
}

export default React.memo(Drawer)
