import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'

import styled from 'styled-components'
import screenWidthSizes from '../../constants/screen-width-sizes'
import Video from '../Video/Video.component'
import { BLUE_GREEN, DARK_BLUE } from '../../constants/colors'

const { TABLET, LAPTOP } = screenWidthSizes

const StyledCarousel = styled(Carousel)`
	.thumbs-wrapper {
		margin: 10px 0;
	}

	.thumbs {
		${({ imageCount }) => (imageCount < 5 ? 'display: flex; justify-content: center;' : null)};

		.thumb {
			border: 1px solid ${BLUE_GREEN};
			border-radius: 3px;
			/* background: ${DARK_BLUE}; */

			img {
				height: 40px;
				object-fit: cover;
				opacity: 0.3;
				transition: all 0.25s ease-in;
			}
		}

		.thumb.selected,
		.carousel .thumb:hover {
			border: 1px solid ${DARK_BLUE};
			background: none;

			img {
				opacity: 1;
			}
		}
	}

	.slide {
		background: none;
	}

	.carousel-status {
		border-radius: 3px;
		padding: 2px 6px;
		background: ${DARK_BLUE + '33'};
	}

	.control-next.control-arrow:before {
		border-left: 8px solid black;
	}

	.control-prev.control-arrow:before {
		border-right: 8px solid black;
	}
`

const ImageWrapper = styled.div`
	position: relative;
	width: 100%;
	max-height: 400px;
	height: ${({ multipleImages }) => (multipleImages ? '400px' : 'auto')};
	margin: 0 auto;

	@media screen and (min-width: ${TABLET.min}px) {
		max-height: 450px;
		height: ${({ multipleImages }) => (multipleImages ? '450px' : 'auto')};
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		max-height: 500px;
		height: ${({ multipleImages }) => (multipleImages ? '500px' : 'auto')};
	}

	img,
	video {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`

const ImageGallery = ({ images }) => {
	const multiple = images != null && images.length > 1

	return (
		<StyledCarousel
			// dynamicHeight
			showStatus={multiple}
			imageCount={images.length}
			showThumbs={multiple}
			showIndicators={false}
		>
			{images != null && images.length > 0
				? images.map((image, index) => (
						<ImageWrapper key={index} multipleImages={multiple}>
							{image.mime.startsWith('image/') ? (
								<img src={image.url} alt="gallery slide" />
							) : image.mime.startsWith('video/') ? (
								<Video src={image.url} alt="gallery slide" />
							) : null}
						</ImageWrapper>
				  ))
				: null}
		</StyledCarousel>
	)
}

export default ImageGallery
