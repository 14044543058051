import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// actions
import { setBackgroundDark } from '../../redux/background/background.actions'

// selectors
import { isBackgroundDarkSelector } from '../../redux/background/background.selectors'

import PrivacyPolicyPage from './PrivacyPolicy.page'

const mapStateToProps = createStructuredSelector({
	isBackgroundDark: isBackgroundDarkSelector,
})

const mapDispatchToProps = (dispatch) => ({
	setBackgroundDark: (isDark) => dispatch(setBackgroundDark(isDark)),
})

const PrivacyPolicyContainer = ({ ...props }) => <PrivacyPolicyPage {...props} />

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicyContainer)
