import { createSelector } from 'reselect'

export const userPostsSelector = (state) => state.userPosts

export const userPostsItemsSelector = createSelector(
	[userPostsSelector],
	(userPosts) => userPosts.items
)

export const isFetchingUserPostsSelector = createSelector(
	[userPostsSelector],
	(userPosts) => userPosts.isFetching
)

export const userPostsErrorSelector = createSelector(
	[userPostsSelector],
	(userPosts) => userPosts.error
)
