import styled from 'styled-components'
import { Link } from 'react-router-dom'

// constants
import { CREAM, DARK_BLUE } from '../../../../constants/colors'
import screenWidthSizes from '../../../../constants/screen-width-sizes'
import { customButtonStyles } from '../../../../components/CustomButton/CustomButton.component'

const { LAPTOP, MOBILE, TABLET, DESKTOP } = screenWidthSizes

export const SectionWrapper = styled.div`
	width: 100%;
	height: auto;
	position: relative;
	background: ${CREAM};
`

export const SectionTitle = styled.h2`
	text-transform: uppercase;
	text-align: center;
	font-size: 18px;
	line-height: 24px;
	font-weight: bold;
	color: ${DARK_BLUE};
	font-family: 'Titillium';
	width: auto;
	margin: 0 auto;

	@media screen and (min-width: ${MOBILE.min}px) {
		font-size: 22px;
		line-height: 30px;
	}

	@media screen and (min-width: ${TABLET.min}px) {
		font-size: 24px;
		line-height: 36px;
		max-width: 75%;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		font-size: 36px;
		line-height: 52px;
	}

	@media screen and (min-width: ${DESKTOP.min}px) {
		max-width: none;
		font-size: 42px;
		line-height: 58px;
	}
`

export const StoryWrapper = styled.div`
	position: relative;
	padding: 15px;
	width: 100%;
	margin: auto;
	max-width: 768px;
	min-height: 300px;
	max-height: 500px;
	overflow-y: visible;

	@media screen and (min-width: ${TABLET.min}px) {
		width: 80%;
		max-height: 550px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		width: 100%;
		max-height: 700px;
	}
`

export const StoryContentWrapper = styled.div`
	position: relative;
	/* padding: 20px; */
	border-radius: 15px;
	background: ${CREAM};
	max-height: 450px;
	display: flex;
	flex-direction: column;
	padding: 5px 15px 15px 15px;

	@media screen and (min-width: ${TABLET.min}px) {
		padding: 0px 30px 30px 30px;
		max-height: 550px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		padding: 0px 45px 45px 45px;
		max-height: 750px;
	}
`

export const StoryImageWrapper = styled.div`
	max-height: 250px;
	height: auto;
	width: 100%;
	margin-bottom: 10px;
	overflow: hidden;
	display: flex;
	justify-content: center;

	@media screen and (min-width: ${TABLET.min}px) {
		max-height: 350px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		max-height: 450px;
	}
`

export const StoryImage = styled.img`
	width: 100%;
	height: auto;
	object-fit: cover;
	border-radius: 5px;
`

export const StoryTitle = styled.h3`
	font-family: 'Titillium';
	font-size: 16px;
	font-weight: normal;
	color: ${DARK_BLUE};
	text-transform: uppercase;
	font-weight: bold;
	margin-bottom: 15px;

	@media screen and (min-width: ${TABLET.min}px) {
		font-size: 20px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		font-size: 24px;
	}
`

export const Excerpt = styled.div`
	min-height: 50px;
	max-height: 200px;
	overflow: hidden;
	height: auto;
	color: ${DARK_BLUE};
	font-size: 16px;
	font-weight: normal;
	margin: auto 0;

	br {
		display: block;
		content: '';
		margin-top: 8px;
	}

	img {
		display: none;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		margin-bottom: 15px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		margin-bottom: 20px;
	}
`

export const Footer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: auto;
	margin-bottom: 5px;
	font-weight: bold;
	color: ${DARK_BLUE};

	@media screen and (min-width: ${TABLET.min}px) {
		margin-bottom: 0;
	}
`

export const StyledReadMoreLink = styled(Link)`
	${customButtonStyles}

	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: -25px;
	color: ${CREAM};
	background-color: ${DARK_BLUE};
	border: 2px solid ${CREAM};
	border-radius: 7px;
`
