import { ActionTypes } from './types'

const INITIAL_STATE = {
	isClosed: false,
}

const generalReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.CLOSE_MESSAGE_BOX:
			return {
				isClosed: true,
			}

		default:
			return state
	}
}

export default generalReducer
