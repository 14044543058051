import React from 'react'
import ReactMarkdown from 'react-markdown'

const PostMarkdown = ({ source }) => (
	<ReactMarkdown
		source={source}
		allowedTypes={[
			'break',
			'blockquote',
			'emphasis',
			'heading',
			'link',
			'list',
			'listItem',
			'paragraph',
			'strong',
			'text',
			'image',
		]}
	/>
)

export default PostMarkdown
