import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// actions
import { fetchStoriesStart } from '../../../../redux/stories/stories.actions'

// selectors
import {
	storiesItemsSelector,
	isFetchingStoriesSelector,
	storiesErrorSelector,
} from '../../../../redux/stories/stories.selectors'

// contained component
import StoriesSection from './Stories.section'

const mapStateToProps = createStructuredSelector({
	items: storiesItemsSelector,
	isFetching: isFetchingStoriesSelector,
	error: storiesErrorSelector,
})

const mapDispatchToProps = (dispatch) => ({
	fetchStoriesStart: () => dispatch(fetchStoriesStart()),
})

const StoriesContainer = ({ ...props }) => <StoriesSection {...props} />

export default connect(mapStateToProps, mapDispatchToProps)(StoriesContainer)
