import styled from 'styled-components'

export const LoaderWrapper = styled.div`
	z-index: 500;
	position: relative;
	top: 0;
	left: 0;
	height: 90%;
	width: 90%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;

	img {
		border-radius: 50%;
	}
`
