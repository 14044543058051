import { ActionTypes } from './thoughts.types'

export const fetchThoughtsStart = () => ({
	type: ActionTypes.FETCH_THOUGHTS_START,
	payload: null,
})

export const fetchThoughtsSuccess = (thoughts) => ({
	type: ActionTypes.FETCH_THOUGHTS_SUCCESS,
	payload: thoughts,
})

export const fetchThoughtsFailure = (error) => ({
	type: ActionTypes.FETCH_THOUGHTS_FAILURE,
	payload: error,
})
