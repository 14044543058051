import styled from 'styled-components'

import { CREAM, DARK_BLUE } from '../../../../constants/colors'
import screenWidthSizes from '../../../../constants/screen-width-sizes'
export const { MOBILE, TABLET, LAPTOP } = screenWidthSizes

export const StoriesSectionWrapper = styled.div`
	position: relative;
	height: auto;
	width: 100%;
	padding: 30px 0;
	background: linear-gradient(
		180deg,
		${CREAM} 0%,
		${CREAM} 50%,
		${DARK_BLUE} 50%,
		${DARK_BLUE} 100%
	);

	display: flex;
	flex-direction: column;
	justify-content: center;
`

export const SectionTitle = styled.h2`
	font-size: 28px;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 2px;
	color: ${DARK_BLUE};
	margin-bottom: 15px;
`

export const ContentWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
	background: transparent;
	max-width: 1200px;

	.BrainhubCarousel__arrows {
		margin-top: 15px;
		padding: 15px;
		border-radius: 50%;
		background-color: ${CREAM};

		&:hover:enabled {
			background-color: ${DARK_BLUE};

			span {
				border-color: ${CREAM};
			}
		}

		span {
			border-color: ${DARK_BLUE};
		}

		@media screen and (min-width: ${LAPTOP.min}px) {
			padding: 18px;
		}
	}

	.BrainhubCarousel__dots .BrainhubCarousel__dot:before {
		background: ${CREAM};
	}
`

export const NoStoriesMessage = styled.div`
	font-size: 18px;
	font-weight: bold;
	text-align: center;
	color: ${CREAM};
`
