import { ActionTypes } from './form.types'

const INITIAL_STATE = {
	name: '',
	emailAddress: '',
	title: '',
	content: '',
	files: [],
	contact: '',
	isSubmitting: false,
	error: null,
}

const formReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.CHANGE_FORM_NAME:
			return {
				...state,
				name: action.payload,
			}

		case ActionTypes.CHANGE_FORM_EMAIL_ADDRESS:
			return {
				...state,
				emailAddress: action.payload,
			}

		case ActionTypes.CHANGE_FORM_CONTACT:
			return {
				...state,
				contact: action.payload,
			}

		case ActionTypes.CHANGE_FORM_TITLE:
			return {
				...state,
				title: action.payload,
			}

		case ActionTypes.CHANGE_FORM_CONTENT:
			return {
				...state,
				content: action.payload,
			}

		case ActionTypes.CHANGE_FORM_FILES:
			return {
				...state,
				files: action.payload,
			}

		case ActionTypes.SUBMIT_FORM_START:
			return {
				...state,
				isSubmitting: true,
			}

		case ActionTypes.SUBMIT_FORM_SUCCESS:
		case ActionTypes.RESET_FORM_STATE:
			return INITIAL_STATE

		case ActionTypes.SUBMIT_FORM_FAILURE:
			return {
				...state,
				error: action.payload,
				submitting: false,
			}

		default:
			return state
	}
}

export default formReducer
