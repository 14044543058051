import moment from 'moment'

export const getTimeElapsedBetweenDates = (pastDate, presentDate) => {
	if (pastDate && presentDate) {
		const present = moment(presentDate)
		const past = moment(pastDate)

		const duration = moment.duration(present.diff(past))

		const days = Math.floor(duration.asDays())
		const hours = Math.floor(duration.asHours())
		const minutes = Math.floor(duration.asMinutes())
		const seconds = Math.floor(duration.asSeconds())

		let number = 0
		let unit = ''

		if (days >= 1) {
			number = days
			unit = 'day'
		} else if (hours >= 1) {
			number = hours
			unit = 'hour'
		} else if (minutes >= 1) {
			number = minutes
			unit = 'minute'
		} else {
			number = seconds
			unit = 'second'
		}

		if (number > 1) {
			unit = unit.concat('s')
		}

		return `${number} ${unit} ago`
	} else {
		return null
	}
}

export const extractVideoIdFromYoutubeUrl = (url) => {
	const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/

	const match = url.match(regExp)

	if (match && match[2].length === 11) {
		return match[2]
	}
}
