import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// actions
import { toggleDrawer } from '../../redux/drawer/drawer.actions'

// selectors
import { isDrawerOpenSelector } from '../../redux/drawer/drawer.selectors'
import { isBackgroundDarkSelector } from '../../redux/background/background.selectors'

import MenuIcon from './MenuIcon.component'

const MenuIconContainer = ({ ...props }) => <MenuIcon {...props} />

const mapStateToProps = createStructuredSelector({
	isOpen: isDrawerOpenSelector,
	isBackgroundDark: isBackgroundDarkSelector,
})

const mapDispatchToProps = (dispatch) => ({
	toggleDrawer: () => dispatch(toggleDrawer()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuIconContainer)
