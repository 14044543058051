import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

// analytics
import ReactGA from 'react-ga'

// styled components
import { BlogPageWrapper } from './Blog.styles'

// sections
import { default as ThoughtsSection } from './sections/Thoughts/Thoughts.container'
import { default as StoriesSection } from './sections/Stories/Stories.container'
import Footer from '../../components/Footer/Footer.component'

const BlogPage = ({ isBackgroundDark, setBackgroundDark }) => {
	// analytics
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search)
	}, [])

	useEffect(() => {
		if (isBackgroundDark) setBackgroundDark(false)
	}, [isBackgroundDark, setBackgroundDark])

	return (
		<BlogPageWrapper id="blog">
			<Helmet>
				<title>Stories + Thoughts</title>
			</Helmet>
			<StoriesSection />
			<ThoughtsSection />
			<Footer />
		</BlogPageWrapper>
	)
}

export default BlogPage
