import styled from 'styled-components'

// constants
import { CREAM, DARK_BLUE } from '../../../../constants/colors'
import screenWidthSizes from '../../../../constants/screen-width-sizes'

// assets
import AboutPageHeaderImage from '../../../../assets/images/About_Page_Header_Image.jpg'

const { LAPTOP } = screenWidthSizes

export const InfoSectionWrapper = styled.div`
	position: relative;
	width: 100%;
	min-height: 100%;
	height: auto;

	/* flex */
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;

	@media screen and (min-width: ${LAPTOP.min}px) {
		flex-direction: row;
	}
`

export const HeaderImageWrapper = styled.div`
	flex-grow: 1;
	flex-shrink: 0;

	background-image: url(${AboutPageHeaderImage});
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;

	min-height: 250px;
	height: 40vh;

	@media screen and (min-width: ${LAPTOP.min}px) {
		flex-basis: 45%;
		height: auto;
	}
`

export const MainDetailsWrapper = styled.div`
	flex-grow: 1;
	flex-shrink: 1;

	background-color: ${DARK_BLUE};
	display: flex;
`

export const MainDetailsText = styled.span`
	position: relative;
	margin: auto;
	padding: 30px;
	font-size: 16px;
	color: ${CREAM};

	h3 {
		margin-bottom: 15px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		padding: 45px;
		font-size: 18px;
	}
`
