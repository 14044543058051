import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'

// constants
import { CREAM } from '../../../../constants/colors'
import screenWidthSizes from '../../../../constants/screen-width-sizes'

const { LAPTOP } = screenWidthSizes

export const LinksSectionWrapper = styled.div`
	height: 100%;
	width: 100%;
	background-color: none;

	display: flex;
	flex-direction: column;
	align-items: center;
`

export const Row = styled.div`
	width: auto;
	height: auto;

	display: flex;

	&:first-child {
		margin-bottom: 15px;
	}
`

export const LogoWrapper = styled.div`
	width: 100px;
	height: auto;

	display: flex;
	align-items: center;

	img {
		width: 100%;
		height: auto;
		vertical-align: middle;
	}

	padding-right: 15px;
	border-right: 2px solid ${CREAM};
`

export const LinksWrapper = styled.div`
	flex: 1;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding-left: 15px;
`

export const StyledHashLink = styled(HashLink)`
	color: ${CREAM};
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 14px;

	&:not(:last-child) {
		margin-bottom: 3px;
	}
`

export const Copyright = styled.div`
	font-size: 14px;
	text-align: center;
	color: ${CREAM};

	@media screen and (min-width: ${LAPTOP.min}px) {
		font-size: 16px;
	}
`

export const StyledAnchor = styled.a`
	text-decoration: underline;
	color: ${CREAM};

	&:hover {
		color: ${CREAM + 'CC'};
	}
`
