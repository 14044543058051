import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// actions
import { setBackgroundDark } from '../../../../redux/background/background.actions'
import { fetchFeaturedPostStart } from '../../../../redux/featured-post/featured-post.actions'

// selectors
import {
	featuredPostItemSelector,
	isFetchingFeaturedPostSelector,
} from '../../../../redux/featured-post/featured-post.selectors'
import { isBackgroundDarkSelector } from '../../../../redux/background/background.selectors'

import FeaturedPostSection from './FeaturedPost.section'

const FeaturedPostContainer = ({ ...props }) => <FeaturedPostSection {...props} />

const mapStateToProps = createStructuredSelector({
	featuredPostItem: featuredPostItemSelector,
	isFetchingFeaturedPost: isFetchingFeaturedPostSelector,
	isBackgroundDark: isBackgroundDarkSelector,
})

const mapDispatchToProps = (dispatch) => ({
	setBackgroundDark: (isDark) => dispatch(setBackgroundDark(isDark)),
	fetchFeaturedPostStart: () => dispatch(fetchFeaturedPostStart()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedPostContainer)
