import React, { useEffect } from 'react'

// styled components
import {
	ThoughtsSectionWrapper,
	ContentWrapper,
	SectionTitle,
	NoThoughtsMessage,
} from './Thoughts.styles'

// components
import Loader from '../../../../components/Loader/Loader.component'
import PostCardCarousel from '../../../../components/PostCardCarousel/PostCardCarousel.component'

const ThoughtsSection = ({ items, isFetching, error, fetchThoughtsStart }) => {
	useEffect(() => {
		fetchThoughtsStart()
	}, [fetchThoughtsStart])

	return (
		<ThoughtsSectionWrapper id="Thoughts">
			<SectionTitle>Thoughts</SectionTitle>
			<ContentWrapper>
				{error ? (
					<div>Failed to load story.</div>
				) : isFetching ? (
					<Loader />
				) : items && items.length > 0 ? (
					<PostCardCarousel posts={items} />
				) : (
					<NoThoughtsMessage>No thoughts available at the moment.</NoThoughtsMessage>
				)}
			</ContentWrapper>
		</ThoughtsSectionWrapper>
	)
}

export default ThoughtsSection
