import { all, call } from 'redux-saga/effects'

import { userPostsSagas } from './user-posts/user-posts.sagas'
import { featuredPostSagas } from './featured-post/featured-post.sagas'
import { formSagas } from './form/form.sagas'
import { thoughtsSagas } from './thoughts/thoughts.sagas'
import { storiesSagas } from './stories/stories.sagas'

export default function* rootSaga() {
	yield all([
		call(userPostsSagas),
		call(featuredPostSagas),
		call(formSagas),
		call(thoughtsSagas),
		call(storiesSagas),
	])
}
