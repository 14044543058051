import { takeLatest, call, put, all } from 'redux-saga/effects'

import { ActionTypes } from './form.types'
import { submitFormSuccess, submitFormFailure } from './form.actions'
import { fetchUserPostsStart } from '../../redux/user-posts/user-posts.actions'

import { sendUserPost } from '../../api/post'

import ReactGA from 'react-ga'

// TODO: Valdiate Form
const validateForm = () => {
	return true
}

const getFormDataFromElement = (formElement) => {
	const { name, email, contact, content, images, title } = formElement

	const data = {
		author_name: name.value,
		author_email_address: email.value,
		contact_number: contact.value,
		content: content.value,
		title: title.value,
	}

	const formData = new FormData()

	for (let file of images.files) {
		if (file && file.name) {
			formData.append(`files.images`, file, file.name)
		}
	}

	formData.append('data', JSON.stringify(data))

	return formData
}

function* submitFormAsync({ payload }) {
	const data = yield call(getFormDataFromElement, payload)
	const valid = yield call(validateForm, payload)

	if (valid) {
		const response = yield call(sendUserPost, data)
		if (response.status === 200) {
			yield put(submitFormSuccess())
		} else {
			yield put(submitFormFailure(response))
		}
	} else {
		yield put(submitFormFailure(new Error('Invalid file types')))
	}
}

function* onSubmitFormStart() {
	yield takeLatest(ActionTypes.SUBMIT_FORM_START, submitFormAsync)
}

function* sendSuccessAlert() {
	yield alert('Your story has been submitted for approval.')

	// analytics
	yield ReactGA.event({
		category: 'Post',
		action: 'Submitted',
	})

	yield put(fetchUserPostsStart())
}

function* sendErrorAlert() {
	yield alert('Failed to submit your story. Please try again later.')
}

function* onSubmitFormSuccess() {
	yield takeLatest(ActionTypes.SUBMIT_FORM_SUCCESS, sendSuccessAlert)
}

function* onSubmitFormFailure() {
	yield takeLatest(ActionTypes.SUBMIT_FORM_FAILURE, sendErrorAlert)
}

export function* formSagas() {
	yield all([call(onSubmitFormStart), call(onSubmitFormSuccess), call(onSubmitFormFailure)])
}
