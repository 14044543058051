import { createSelector } from 'reselect'

export const thoughtsSelector = (state) => state.thoughts

export const thoughtsItemsSelector = createSelector(
	[thoughtsSelector],
	(thoughts) => thoughts.items
)

export const isFetchingThoughtsSelector = createSelector(
	[thoughtsSelector],
	(thoughts) => thoughts.isFetching
)

export const thoughtsErrorSelector = createSelector(
	[thoughtsSelector],
	(thoughts) => thoughts.error
)
