import React, { useRef } from 'react'
import styled from 'styled-components'

import { customButtonStyles } from '../CustomButton/CustomButton.component'

const VideoWrapper = styled.div`
	width: 100%;
	height: 100%;

	img,
	video {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`
const PlayButton = styled.button`
	${customButtonStyles}
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

const Video = ({ ...props }) => {
	const vidRef = useRef(null)
	const buttonRef = useRef(null)

	const handlePlay = () => {
		if (buttonRef && vidRef) {
			buttonRef.current.style.display = 'none'
			vidRef.current.play()
		}
	}

	return (
		<VideoWrapper>
			<video ref={vidRef} {...props} playsInline controls />
			<PlayButton ref={buttonRef} onClick={handlePlay}>
				PLAY
			</PlayButton>
		</VideoWrapper>
	)
}

export default Video
