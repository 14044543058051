import React from 'react'

// react router
import { Switch, Route } from 'react-router-dom'

// pages
import HomePage from '../../pages/Home/Home.page'
import AboutPage from '../../pages/About/About.page'
import PostPage from '../../pages/Post/Post.page'
import CampaignsPage from '../../pages/Campaigns/Campaigns.page'
import { default as BlogPage } from '../../pages/Blog/Blog.container'
import { default as PrivacyPolicy } from '../../pages/Privacy-Policy/PrivacyPolicy.container'

// styled components
import { AppWrapper } from './App.styles'

// components
import { default as FixedComponents } from '../FixedComponents/FixedComponents.container'
import Main from '../Main/Main.component'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import { default as MessageBox } from '../../components/MessageBox/MessageBox.container'

const App = ({ isMessageBoxClosed }) => {
	return (
		<AppWrapper id="App">
			<FixedComponents />
			{isMessageBoxClosed ? null : <MessageBox />}

			<Main id="Body">
				<Switch>
					<ErrorBoundary>
						<Route exact path="/" component={HomePage} />
						<Route exact path="/about" component={AboutPage} />
						<Route exact path="/blog" component={BlogPage} />
						<Route exact path="/campaigns" component={CampaignsPage} />
						{/* <Route exact path="/posts" render={() => <h1>Tags</h1>} /> */}
						<Route exact path="/posts/:id" render={() => <PostPage fromFeed />} />
						<Route exact path="/thoughts/:id" render={() => <PostPage />} />
						<Route exact path="/stories/:id" render={() => <PostPage />} />
						<Route exact path="/privacy-policy" component={PrivacyPolicy} />
					</ErrorBoundary>
				</Switch>
			</Main>
		</AppWrapper>
	)
}

export default App
