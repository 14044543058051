import { ActionTypes } from './user-posts.types'

export const fetchUserPostsStart = () => ({
	type: ActionTypes.FETCH_USER_POSTS_START,
	payload: null,
})

export const fetchUserPostsSuccess = (userPosts) => ({
	type: ActionTypes.FETCH_USER_POSTS_SUCCESS,
	payload: userPosts,
})

export const fetchUserPostsFailure = (error) => ({
	type: ActionTypes.FETCH_USER_POSTS_FAILURE,
	payload: error,
})
