import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

//actions
import { setBackgroundDark } from '../../../../redux/background/background.actions'
import { fetchUserPostsStart } from '../../../../redux/user-posts/user-posts.actions'

// selectors
import {
	userPostsItemsSelector,
	isFetchingUserPostsSelector,
} from '../../../../redux/user-posts/user-posts.selectors'
import { isBackgroundDarkSelector } from '../../../../redux/background/background.selectors'

// components
import UserPostsSection from './UserPosts.section'

const UserPostsContainer = ({ ...props }) => <UserPostsSection {...props} />

const mapStateToProps = createStructuredSelector({
	posts: userPostsItemsSelector,
	isFetching: isFetchingUserPostsSelector,
	isBackgroundDark: isBackgroundDarkSelector,
})

const mapDispatchToProps = (dispatch) => ({
	fetchUserPostsStart: () => dispatch(fetchUserPostsStart()),
	setBackgroundDark: () => dispatch(setBackgroundDark()),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserPostsContainer)
