import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// actions
import { closeMessageBox } from '../../redux/message-box/actions'

import { isMessageBoxClosedSelector } from '../../redux/message-box/selectors'

// component
import Notice from './MessageBox.component'

const NoticeContainer = ({ ...props }) => <Notice {...props} />

const mapStateToProps = createStructuredSelector({
	isMessageBoxClosed: isMessageBoxClosedSelector,
})

const mapDispatchToProps = (dispatch) => ({
	closeMessageBox: () => dispatch(closeMessageBox()),
})

export default connect(mapStateToProps, mapDispatchToProps)(NoticeContainer)
