import { createSelector } from 'reselect'

const featuredPostSelector = (state) => state.featuredPost

export const featuredPostItemSelector = createSelector(
	[featuredPostSelector],
	(featuredPost) => featuredPost.item
)

export const isFetchingFeaturedPostSelector = createSelector(
	[featuredPostSelector],
	(featuredPost) => featuredPost.isFetching
)
