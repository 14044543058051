import React from 'react'
import { Link } from 'react-router-dom'

// assets
import CreamLogo from '../../../../assets/images/Cream_Logo.png'

// styled components
import {
	LinksSectionWrapper,
	Row,
	LogoWrapper,
	LinksWrapper,
	StyledHashLink,
	Copyright,
	StyledAnchor,
} from './Links.styles'

const Links = () => (
	<LinksSectionWrapper>
		<Row>
			<LogoWrapper>
				<Link to="/">
					<img src={CreamLogo} alt="logo" />
				</Link>
			</LogoWrapper>
			<LinksWrapper>
				<StyledHashLink to="/#write">Share Your Story</StyledHashLink>
				<StyledHashLink to="/#feed">{"Gretchen's Wall"}</StyledHashLink>
				<StyledHashLink to="/blog">Stories + Thoughts</StyledHashLink>
				<StyledHashLink to="/campaigns">Campaigns</StyledHashLink>
				<StyledHashLink to="/about">About</StyledHashLink>
			</LinksWrapper>
		</Row>
		<Row>
			<Copyright>
				<div>&copy; Woman In Action by Gretchen Ho</div>
				<div>
					Designed & Developed by{' '}
					<StyledAnchor href="https://www.simmer-studios.com" target="_blank">
						Simmer Studios
					</StyledAnchor>
				</div>
			</Copyright>
		</Row>
	</LinksSectionWrapper>
)

export default Links
