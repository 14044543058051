import { ActionTypes } from './background.types'

const INITIAL_STATE = {
	isDark: true,
}

const backgroundReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.SET_BACKGROUND_DARK:
			return {
				isDark: action.payload,
			}

		default:
			return state
	}
}

export default backgroundReducer
