import styled from 'styled-components'
import { Link } from 'react-router-dom'

// constants
import { customButtonStyles } from '../../components/CustomButton/CustomButton.component'
import { CREAM, DARK_BLUE, BLUE_GREEN } from '../../constants/colors'
import screenWidthSizes from '../../constants/screen-width-sizes'

// components
import CustomButton from '../CustomButton/CustomButton.component'
import { LAPTOP } from '../../pages/Home/sections/UserPosts/UserPosts.styles'

const { TABLET } = screenWidthSizes

// styled components
export const FormWrapper = styled.div`
	width: 100%;
	max-width: 900px;
	height: auto;
	margin: 0 auto;
`

export const Form = styled.form``

export const FormContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	/* padding: 10px; */
`

export const TextFieldsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
`

export const Input = styled.input`
	font-family: 'Titillium';
	background: none;
	border: none;
	border-radius: 10px;
	padding: 5px 15px;
	width: 100%;
	color: ${DARK_BLUE};
	font-weight: bold;
	font-size: 16px;
	background: ${CREAM};
	margin: 5px 0px;

	&::placeholder {
		color: ${DARK_BLUE + 'AA'};
		text-transform: capitalize;
		font-size: 16px;
	}

	@media screen and (min-width: ${TABLET.min}px) {
		width: 49%;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		/* width: 24%; */
		padding: 8px 15px;
	}
`

export const Textarea = styled.textarea`
	font-family: 'Titillium';
	font-size: 16px;
	font-weight: bold;
	color: ${CREAM};
	border: 2px solid ${CREAM};
	background: none;
	border-radius: 15px;
	padding: 15px;
	min-height: 300px;
	letter-spacing: 1px;
	margin: 10px 0;

	&::placeholder {
		font-weight: bold;
		font-size: 16px;
		color: ${CREAM};
		letter-spacing: 1px;
	}

	@media screen and (min-width: ${TABLET.min}px) {
		/* padding: 30px; */
	}
`

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

export const UploadButtonWrapper = styled.div`
	label.custom-file-upload {
		${customButtonStyles}
		cursor: pointer;
		padding: 6px 20px;
		color: ${CREAM};
		border: 1px solid ${CREAM};

		flex: 1;
	}

	input[type='file'] {
		display: none;
	}

	.filename {
		color: ${CREAM};
		width: 50px;
		overflow: hidden;
	}
`

export const SubmitButton = styled(CustomButton)`
	padding: 7px 20px;
	cursor: pointer;
`

export const ResetForm = styled.div`
	width: 300px;
	color: ${DARK_BLUE};
	text-align: center;
	margin: 30px auto;
	opacity: 0;
	cursor: pointer;
	padding: 3px 5px;
	border-radius: 5px;
	background: ${CREAM};

	@keyframes fadeIn {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	animation-name: fadeIn;
	animation-duration: 0.5s;
	animation-delay: 15s;
	animation-timing-function: ease-in;
	animation-fill-mode: forwards;
`

export const PrivacyPolicy = styled.div`
	font-size: 14px;
	text-align: center;
	color: ${CREAM};
	margin-top: 10px;
`

export const StyledLink = styled(Link)`
	color: ${BLUE_GREEN};
	text-decoration: underline;
`
