import styled from 'styled-components'
import { CREAM, DARK_BLUE } from '../../../../constants/colors'
import screenWidthSizes from '../../../../constants/screen-width-sizes'

const { LAPTOP, MOBILE, TABLET, DESKTOP } = screenWidthSizes

export const Wrapper = styled.div`
	width: 100%;
	height: auto;
	position: relative;
	background: ${CREAM};
`

export const Title = styled.h2`
	text-transform: uppercase;
	text-align: center;
	font-size: 18px;
	line-height: 24px;
	font-weight: bold;
	margin-bottom: 15px;
	color: ${DARK_BLUE};
	font-family: 'Titillium';
	width: auto;
	margin: 0 auto;

	@media screen and (min-width: ${MOBILE.min}px) {
		font-size: 22px;
	}

	@media screen and (min-width: ${TABLET.min}px) {
		font-size: 24px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		font-size: 36px;
	}

	@media screen and (min-width: ${DESKTOP.min}px) {
		font-size: 42px;
	}
`

export const VideoWrapper = styled.div`
	width: 100%;
	max-width: 768px;
	margin: 0 auto;

	@media screen and (min-width: ${LAPTOP.min}px) {
		margin: 45px auto 15px auto;
	}
`
