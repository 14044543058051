import React from 'react'
import moment from 'moment'

import PostMarkdown from '../PostMarkdown/PostMarkdown.component'

import { Wrapper, MetaData, Author, Date, Content } from './Comment.styles'

const Comment = ({ comment }) => (
	<Wrapper>
		<MetaData>
			<Author>{comment.author_name}</Author>
			<Date>{moment(comment.createdAt).fromNow()}</Date>
		</MetaData>
		<Content>
			{comment.content != null && comment.content.length > 0 ? (
				comment.content.split('\n').map((item, key) => (
					<span key={key}>
						<PostMarkdown source={item} />
						<br />
					</span>
				))
			) : (
				<div>No comment.</div>
			)}
		</Content>
	</Wrapper>
)

export default Comment
