import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

// analytics
import ReactGA from 'react-ga'

// components
import BannerSection from './sections/Banner/Banner.section'
import DescriptionSection from './sections/Description/Description.section'
// import DonationOptionsSection from './sections/DonationOptions/DonationOptions.section'
// import ReceiptSection from './sections/Receipt/Receipt.section'
import Footer from '../../components/Footer/Footer.component'

const Wrapper = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
`

const CampaignsPage = () => {
	// analytics
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search)
	}, [])

	return (
		<Wrapper>
			<Helmet>
				<title>Donate A Bike | Campaigns</title>
			</Helmet>
			<BannerSection />
			<DescriptionSection />
			{/* <DonationOptionsSection /> */}
			{/* <ReceiptSection /> */}
			<Footer />
		</Wrapper>
	)
}

export default CampaignsPage
