import React from 'react'

import { MenuIconWrapper, StyledMenuBars } from './MenuIcon.styles'

const MenuIcon = ({ isOpen, isBackgroundDark, toggleDrawer }) => {
	return (
		<MenuIconWrapper
			id="menu-icon"
			onClick={toggleDrawer}
			className={isOpen ? 'open' : null}
			light={isBackgroundDark}
		>
			<StyledMenuBars light={isBackgroundDark}>
				<span></span>
			</StyledMenuBars>
		</MenuIconWrapper>
	)
}

export default MenuIcon
