import { createSelector } from 'reselect'

export const formSelector = (state) => state.form

export const formNameSelector = createSelector([formSelector], (form) => form.name)

export const formEmailAddressSelector = createSelector([formSelector], (form) => form.emailAddress)

export const formContactSelector = createSelector([formSelector], (form) => form.contact)

export const formTitleSelector = createSelector([formSelector], (form) => form.title)

export const formContentSelector = createSelector([formSelector], (form) => form.content)

export const formFilesSelector = createSelector([formSelector], (form) => form.files)

export const isFormSubmittingSelector = createSelector([formSelector], (form) => form.isSubmitting)

export const formErrorSelector = createSelector([formSelector], (form) => form.error)
