import React from 'react'
import moment from 'moment'

import { Wrapper, Date, Category, Tag } from './Metadata.styles'

const Metadata = ({ date, category, tags }) => (
	<Wrapper>
		{date ? <Date>{moment(date).format('MMMM D YYYY, h:mm A')}</Date> : null}

		<Category to={category ? `/blog#${category.name}` : '/#feed'}>
			{category ? category.name : 'User Posts'}
		</Category>

		{tags && tags.length > 0
			? tags.map((tag) => (
					// <Tag to={tag.id ? `/posts?tags_in=${tag.id}` : ''}> key={tag.id}>
					// 	{tag.name}
					// </Tag>
					<Tag to={'#'} key={tag.id}>
						{tag.name}
					</Tag>
			  ))
			: null}
	</Wrapper>
)

export default Metadata
