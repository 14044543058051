import styled, { css } from 'styled-components'
import { HashLink } from 'react-router-hash-link'

import { CREAM, DARK_BLUE, BLUE_GREEN } from '../../../../constants/colors'

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 15px;
`

export const Date = styled.span`
	font-size: 14px;
	flex-basis: 100%;
	flex-shrink: 0;
	color: ${DARK_BLUE};
`

const DataStyles = css`
	cursor: pointer;
	color: ${CREAM};
	font-size: 14px;
	padding: 2px 12px;
	border-radius: 3px;
	margin-right: 5px;
	margin-top: 5px;
`

export const Category = styled(HashLink)`
	${DataStyles}

	background-color: ${BLUE_GREEN};
`

export const Tag = styled(HashLink)`
	${DataStyles}
	cursor: unset;
	background-color: ${DARK_BLUE};
`
