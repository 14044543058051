import React, { useRef, useEffect } from 'react'

const VideoPlayer = ({ source, poster }) => {
	const videoRef = useRef(null)

	useEffect(() => {
		const { current: videoElement } = videoRef
		videoElement.setAttribute('muted', '')
	}, [])

	return (
		<video
			src={source}
			playsInline
			autoPlay
			muted
			loop
			ref={videoRef}
			onClick={(ev) => ev.target.play()}
			poster={poster}
		></video>
	)
}

export default VideoPlayer
