import axios from 'axios'

import { API } from '../constants/urls'

export const getFeaturedVideo = async () => {
	try {
		const response = await axios({
			url: `${API}/youtube-video`,
			method: 'GET',
		})

		return response
	} catch (error) {
		return error
	}
}

export const getUserPosts = async (start = 0, limit = 30) => {
	try {
		const response = await axios({
			url: `${API}/posts`,
			method: 'GET',
			params: {
				category_null: true,
				_start: start,
				_limit: limit,
				_sort: 'createdAt:DESC',
			},
		})
		return response
	} catch (error) {
		return error
	}
}

export const getLatestFeaturedPost = async () => {
	try {
		const response = await axios({
			url: `${API}/posts/featured`,
			method: 'GET',
		})
		return response
	} catch (error) {
		return error
	}
}

export const getPostById = async (id) => {
	try {
		const response = await axios({
			url: `${API}/posts/${id}`,
			method: 'GET',
		})
		return response
	} catch (error) {
		return error
	}
}

export const getCommentsByPostId = async (id) => {
	try {
		const response = await axios({
			url: `${API}/posts/${id}/comments`,
			method: 'GET',
			params: {
				_sort: 'createdAt:DESC',
			},
		})
		return response
	} catch (error) {
		return error
	}
}

export const getStories = async () => {
	try {
		const response = await axios({
			url: `${API}/posts/stories`,
			method: 'GET',
			params: {
				_sort: 'createdAt:DESC',
			},
		})
		return response
	} catch (error) {
		return error
	}
}

export const getThoughts = async () => {
	try {
		const response = await axios({
			url: `${API}/posts/thoughts`,
			method: 'GET',
			params: {
				_sort: 'createdAt:DESC',
			},
		})
		return response
	} catch (error) {
		return error
	}
}

export const getCampaigns = async () => {
	return await axios.get(`${API}/campaigns?running=true&_sort=createdAt:DESC`)
}
