import React, { useState, useEffect } from 'react'
import VisibilitySensor from 'react-visibility-sensor'

import YoutubeEmbed from '../../../../components/YoutubeEmbed/YoutubeEmbed.component'

import { getFeaturedVideo } from '../../../../api/get'
import { extractVideoIdFromYoutubeUrl } from '../../../../utils/index'

import { Wrapper, VideoWrapper } from './FeaturedVideo.styles'

const FeaturedVideoSection = ({ isBackgroundDark, setBackgroundDark }) => {
	const [state, setState] = useState({
		video: null,
		videoId: null,
		fetching: false,
		fetched: false,
	})

	const { video, videoId, fetching, fetched } = state

	const getVideo = async () => {
		const response = await getFeaturedVideo()

		if (response.status === 200) {
			const video = response.data

			if (video.show) {
				const videoId = extractVideoIdFromYoutubeUrl(video.url)

				if (videoId != null) {
					setState({
						video,
						videoId,
						fetching: false,
						fetched: true,
					})
				}
			}
		}
	}

	useEffect(() => {
		if (!fetching && !fetched && video == null) {
			getVideo()
		}
	}, [video, fetching, fetched])

	return video != null ? (
		<Wrapper>
			<VisibilitySensor
				onChange={(isVisible) => {
					if (isVisible && isBackgroundDark) setBackgroundDark(false)
				}}
			>
				<VideoWrapper>
					<YoutubeEmbed videoId={videoId} />
				</VideoWrapper>
			</VisibilitySensor>
		</Wrapper>
	) : null
}

export default FeaturedVideoSection
