import { takeLatest, call, put, all } from 'redux-saga/effects'

import { ActionTypes } from './user-posts.types'
import { fetchUserPostsSuccess, fetchUserPostsFailure } from './user-posts.actions'
import { getUserPosts } from '../../api/get'

// TODO handle status 5xx
function* fetchUserPostsAsync() {
	const response = yield call(getUserPosts)
	if (response.status === 200) {
		yield put(fetchUserPostsSuccess(response.data))
	} else {
		yield put(fetchUserPostsFailure(response))
	}
}

export function* fetchUserPostsStart() {
	yield takeLatest(ActionTypes.FETCH_USER_POSTS_START, fetchUserPostsAsync)
}

export function* userPostsSagas() {
	yield all([call(fetchUserPostsStart)])
}
