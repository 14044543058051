import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// selectors
import { isMessageBoxClosedSelector } from '../../redux/message-box/selectors'

import FixedComponents from './FixedComponents.component'

const FixedComponentsContainer = ({ ...props }) => <FixedComponents {...props} />

const mapStateToProps = createStructuredSelector({
	isMessageBoxClosed: isMessageBoxClosedSelector,
})

export default connect(mapStateToProps)(FixedComponentsContainer)
