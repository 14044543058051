import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { CREAM, DARK_BLUE, BLUE_GREEN } from '../../../../constants/colors'

export const Wrapper = styled.section`
	background-color: ${CREAM};
	padding: 60px 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const Paragraph = styled.p`
	font-size: 16px;
	font-weight: bold;
	color: ${DARK_BLUE};
	text-align: center;
	max-width: 768px;
	line-height: 1.4;

	&:not(:last-child) {
		margin-bottom: 15px;
	}
`

export const StyledLink = styled(Link)`
	&,
	&:active,
	&:focus {
		color: ${BLUE_GREEN};
		text-decoration: underline;
	}
`
