import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// selectors
import { isBackgroundDarkSelector } from '../../../redux/background/background.selectors'

import Messenger from './Messenger.component'

const mapStateToProps = createStructuredSelector({
	isBackgroundDark: isBackgroundDarkSelector,
})

const MessengerContainer = ({ ...props }) => <Messenger {...props} />

export default connect(mapStateToProps)(MessengerContainer)
