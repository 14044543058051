import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

// analytics
import ReactGA from 'react-ga'

// sections
import { default as BannerSection } from './sections/Banner/Banner.container'
import { default as FeaturedVideoSection } from './sections/FeaturedVideo/FeaturedVideo.container'
import { default as FeaturedPostSection } from './sections/FeaturePost/FeaturedPost.container'
import { default as WriteSection } from './sections/Write/Write.container'

import { default as UserPostsSection } from './sections/UserPosts/UserPosts.container'
import Footer from '../../components/Footer/Footer.component'

// styled components
import { HomePageWrapper } from './Home.styles'

const HomePage = () => {
	// analytics
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search)
	}, [])

	return (
		<HomePageWrapper id="HomePage">
			<Helmet>
				<title>Woman In Action</title>
			</Helmet>
			<BannerSection />
			<FeaturedVideoSection />
			<FeaturedPostSection />
			<WriteSection />
			<UserPostsSection />
			<Footer />
		</HomePageWrapper>
	)
}

export default HomePage
