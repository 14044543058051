import { ActionTypes } from './form.types'

export const changeFormName = (name) => ({
	type: ActionTypes.CHANGE_FORM_NAME,
	payload: name,
})

export const changeFormEmail = (email) => ({
	type: ActionTypes.CHANGE_FORM_EMAIL_ADDRESS,
	payload: email,
})

export const changeFormContact = (contact) => ({
	type: ActionTypes.CHANGE_FORM_CONTACT,
	payload: contact,
})

export const changeFormTitle = (title) => ({
	type: ActionTypes.CHANGE_FORM_TITLE,
	payload: title,
})

export const changeFormContent = (content) => ({
	type: ActionTypes.CHANGE_FORM_CONTENT,
	payload: content,
})

export const changeFormFiles = (files) => ({
	type: ActionTypes.CHANGE_FORM_FILES,
	payload: files,
})

export const submitFormStart = (formElement) => ({
	type: ActionTypes.SUBMIT_FORM_START,
	payload: formElement,
})

export const submitFormSuccess = () => ({
	type: ActionTypes.SUBMIT_FORM_SUCCESS,
	payload: null,
})

export const submitFormFailure = (error) => ({
	type: ActionTypes.SUBMIT_FORM_FAILURE,
	payload: error,
})

export const resetFormState = () => ({
	type: ActionTypes.RESET_FORM_STATE,
	payload: null,
})
