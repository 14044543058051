import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'

// constants
import screenWidthSizes from '../../constants/screen-width-sizes'
import { CREAM, DARK_BLUE, BLUE_GREEN } from '../../constants/colors'

const { MOBILE, TABLET, LAPTOP } = screenWidthSizes

export const PostPageWrapper = styled.div`
	position: relative;
	/* padding: 15px; */
	width: 100%;
	min-height: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: linear-gradient(
		180deg,
		${BLUE_GREEN} 0%,
		${BLUE_GREEN} 45%,
		${CREAM} 45%,
		${CREAM} 100%
	);

	background-attachment: fixed;
`

export const PostWrapper = styled.div`
	width: 95%;
	position: relative;
	border-radius: 15px;
	padding: 15px;
	background: ${CREAM};
	margin-top: 60px;
	margin-bottom: 30px;

	@media screen and (min-width: ${MOBILE.min}px) {
		margin-top: 80px;
	}

	@media screen and (min-width: ${TABLET.min}px) {
		padding: 30px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		width: 100%;
		padding: 45px;
		max-width: 768px;
	}
`

export const ImageWrapper = styled.div`
	width: 100%;
`
export const ContentWrapper = styled.div`
	width: 100%;
	height: auto;
	color: ${DARK_BLUE};
`

export const Header = styled.div`
	margin: 15px 0px;
	display: flex;
	flex-direction: column;

	.author {
		font-weight: bold;
		font-size: 16px;
	}
`

export const PostTitle = styled.h1`
	font-family: 'Titillium';
	font-size: 26px;
	line-height: 26px;
	margin-bottom: 6px;
`

export const Content = styled.div`
	width: 100%;
	font-size: 16px;

	img {
		max-width: 100%;
		object-fit: contain;
		display: block;
		margin: 0 auto;
	}

	span:not(:last-child) {
		br {
			margin-top: 12px; /* change this to whatever height you want it */
		}
	}

	br {
		display: block; /* makes it have a width */
		content: ''; /* clears default height */
	}
`

export const CommentsWrapper = styled.div`
	width: 100%;
	height: auto;
	margin-top: 30px;
`

export const CommentsSectionTitle = styled.h3`
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: bold;
	font-size: 18px;
	color: ${DARK_BLUE};
	margin-bottom: 5px;
`

export const Actions = styled.div`
	margin-top: 15px;
	display: flex;
	flex-direction: column;

	.share {
		letter-spacing: 1px;
	}
`

export const Row = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
`

export const SocialMediaShare = styled.div`
	width: 90px;
	color: ${DARK_BLUE};
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left;
`

export const IconsWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	.icon {
		width: 36px;
		height: 36px;
		border-radius: 5px;

		&:first-child {
			margin-right: 5px;
		}
	}
`

export const CopyLinkButton = styled.button`
	cursor: pointer;
	margin-left: 5px;
	padding: 8px 12px;
	border: none;
	border-radius: 5px;

	.copy-link {
		width: 15px;
		vertical-align: middle;
	}
`

export const StyledLink = styled(HashLink)`
	cursor: pointer;

	button {
		cursor: pointer;
		width: auto;
		padding: 8px 20px;
		font-size: 12px;
	}

	@media screen and (min-width: ${MOBILE.min}px) {
		button {
			width: 200px;
		}
	}
`

export const WriteStoryLink = styled(HashLink)`
	cursor: pointer;
	width: 100%;

	button {
		cursor: pointer;
		padding: 8px 20px;
		width: 100%;
		font-size: 12px;
		color: ${CREAM};
		background: ${BLUE_GREEN};
	}

	@media screen and (min-width: ${MOBILE.min}px) {
		width: auto;

		button {
			width: 200px;
		}
	}
`

export const ErrorWrapper = styled.div`
	width: 100%;
	text-align: center;
	padding: 15px;
	display: flex;
	flex-direction: column;
`

export const ErrorMessage = styled.span`
	margin: 15px;
`
