import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// selectors
import { isBackgroundDarkSelector } from '../../redux/background/background.selectors'

import SocialMedia from './SocialMedia.component'

const SocialMediaContainer = ({ ...props }) => <SocialMedia {...props} />

const mapStateToProps = createStructuredSelector({
	isBackgroundDark: isBackgroundDarkSelector,
})

export default connect(mapStateToProps)(SocialMediaContainer)
