import styled from 'styled-components'
import { Link } from 'react-router-dom'

// constants
import screenWidthSizes from '../../constants/screen-width-sizes'

const { SMALL_MOBILE, LAPTOP, TABLET } = screenWidthSizes

export const StyledImage = styled.img`
	position: absolute;
	vertical-align: middle;
	height: 75px;
	width: auto;
	transition: all 0.5s ease-in-out;
	opacity: ${({ show }) => (show ? 1 : 0)};
`
export const StyledLink = styled(Link)`
	position: fixed;
	z-index: 700;
	height: auto;
	position: fixed;
	top: 45px;
	left: 35px;

	@media screen and (max-width: ${LAPTOP.max}px) {
		top: 30px;
		left: 20px;

		${StyledImage} {
			height: 65px;
		}
	}

	@media screen and (max-width: ${TABLET.max}px) {
		top: 20px;
		left: 15px;

		${StyledImage} {
			height: 50px;
		}
	}

	@media screen and (max-width: ${SMALL_MOBILE.max}px) {
		top: 10px;
		left: 10px;

		${StyledImage} {
			height: 40px;
		}
	}
`
