import styled from 'styled-components'

// constants
import { DARK_BLUE, BLUE_GREEN, CREAM } from '../../../../constants/colors'
import screenWidthSizes from '../../../../constants/screen-width-sizes'

// assets
import WriteBGDesktop from '../../../../assets/images/Write_BG_Desktop.jpg'
import WriteBGMobile from '../../../../assets/images/Write_BG_Mobile.jpg'

const { MOBILE, TABLET, LAPTOP, DESKTOP } = screenWidthSizes

export const WriteSectionWrapper = styled.div`
	/* size */
	min-height: 100%;
	height: auto;
	width: 100%;
	padding: 15px;

    /* background: radial-gradient(circle at 50% -50%, ${BLUE_GREEN} 30%, ${DARK_BLUE} 60%); */
    background-image: url(${WriteBGMobile});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
	
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media screen and (min-width: ${LAPTOP.min}px) {
		background-image: url(${WriteBGDesktop});
	}
`

export const ContentWrapper = styled.div`
	padding: 0;
	width: 100%;
	margin-top: 90px;

	display: flex;
	flex-direction: column;

	@media screen and (min-width: ${MOBILE.min}px) {
		padding: 15px;
	}

	@media screen and (min-width: ${TABLET.min}px) {
		margin-top: 150px;
	}

	@media screen and (min-width: ${DESKTOP.min}px) {
		margin-top: 150px;
	}
`
export const HeaderWrapper = styled.div`
	color: ${CREAM};
	text-align: center;
	margin-bottom: 15px;

	.underlined {
		text-decoration: underline;
		color: ${CREAM};
	}

	@media screen and (min-width: ${TABLET.min}px) {
		margin-bottom: 30px;
	}
`

export const ShareText = styled.span`
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 2px;
	font-size: 16px;
`

export const QuestionText = styled.h3`
	font-family: 'Titillium';
	font-size: 36px;
	line-height: 36px;
	font-weight: bold;

	@media screen and (min-width: ${TABLET.min}px) {
		font-size: 48px;
		line-height: 48px;
	}
`
