import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

// analytics
import ReactGA from 'react-ga'

// components
import { default as InfoSection } from './sections/Info/Info.container'
import Footer from '../../components/Footer/Footer.component'

// styled components
import { AboutPageWrapper } from './About.styles'

const AboutPage = () => {
	// analytics
	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search)
	}, [])

	return (
		<AboutPageWrapper>
			<Helmet>
				<title>About</title>
			</Helmet>
			<InfoSection />
			<Footer />
		</AboutPageWrapper>
	)
}

export default AboutPage
