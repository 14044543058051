import { ActionTypes } from './user-posts.types'

const initialState = {
	items: [],
	isFetching: false,
	error: null,
}

const userPostsReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.FETCH_USER_POSTS_START:
			return {
				...state,
				isFetching: true,
			}

		case ActionTypes.FETCH_USER_POSTS_SUCCESS:
			return {
				...state,
				items: action.payload,
				isFetching: false,
			}

		case ActionTypes.FETCH_USER_POSTS_FAILURE:
			return {
				...state,
				error: action.payload,
				isFetching: false,
			}

		default:
			return state
	}
}

export default userPostsReducer
