import React from 'react'
import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import { useMediaQuery } from 'react-responsive'

import PostCard from '../PostCard/PostCard.component'
import screenWidthSizes from '../../constants/screen-width-sizes'

const { MOBILE, TABLET } = screenWidthSizes

const PostCardCarousel = ({ posts }) => {
	const isMobile = useMediaQuery({ maxWidth: MOBILE.max })
	const isTablet = useMediaQuery({ minWidth: TABLET.min, maxWidth: TABLET.max })
	const isMobileOrTablet = useMediaQuery({ maxWidth: TABLET.max })
	const isSingle = posts != null && posts.length === 1
	const isThreeOrMore = posts != null && posts.length > 2

	return (
		<Carousel
			dots={isSingle ? false : isMobile ? true : (isTablet && isThreeOrMore) || isThreeOrMore}
			arrows={isMobileOrTablet ? false : isThreeOrMore}
			slidesPerPage={
				isSingle
					? 1
					: isMobile
					? 1.1
					: (isTablet && isThreeOrMore) || isThreeOrMore
					? 2.1
					: 2
			}
			draggable={
				isSingle ? false : isMobile ? true : (isTablet && isThreeOrMore) || isThreeOrMore
			}
		>
			{posts != null && posts.length > 0 ? (
				posts.map((post, index) => <PostCard key={index} post={post} />)
			) : (
				<div>No post available.</div>
			)}
		</Carousel>
	)
}
export default PostCardCarousel
