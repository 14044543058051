import styled from 'styled-components'

// constants
import { YELLOW, DARK_BLUE, CREAM } from '../../../../constants/colors'
import screenWidthSizes from '../../../../constants/screen-width-sizes'

const { MOBILE, TABLET, LAPTOP, DESKTOP } = screenWidthSizes

export const BannerWrapper = styled.section`
	position: relative;
	height: 90vh;
	min-height: 300px;
	max-height: 900px;
	padding: 10px;
	background-color: ${YELLOW};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media screen and (min-width: ${MOBILE.min}px) {
		padding: 15px;
	}
`

export const BannerHeading = styled.h2`
	color: ${DARK_BLUE};
	font-size: 36px;
	text-align: center;
	line-height: 1;
	text-transform: uppercase;
	margin-bottom: 30px;

	@media screen and (min-width: ${MOBILE.min}px) {
		font-size: 44px;
	}

	@media screen and (min-width: ${TABLET.min}px) {
		font-size: 60px;
	}
`

export const BannerText = styled.p`
	color: ${CREAM};
	font-size: 18px;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 2px;
	margin-bottom: 10px;
`

export const SmallText = styled.p`
	color: ${CREAM};
	font-size: 14px;
	text-align: center;

	@media screen and (min-width: ${TABLET.min}px) {
		font-size: 16px;
	}
`

export const BicycleImage = styled.img`
	width: 150px;
	position: absolute;
	left: 0;
	bottom: -32px;

	@media screen and (min-width: ${MOBILE.min}px) {
		width: 200px;
		bottom: -42px;
	}

	@media screen and (min-width: ${TABLET.min}px) {
		width: 250px;
		bottom: -53px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		width: 300px;
		bottom: -63px;
	}

	@media screen and (min-width: ${DESKTOP.min}px) {
		width: 325px;
		bottom: -69px;
	}

	@media screen and (min-width: ${DESKTOP.min + 400}px) {
		width: 360px;
		bottom: -76px;
	}
`
