import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// selectors
import { isBackgroundDarkSelector } from '../../redux/background/background.selectors'

import HeaderLogo from './HeaderLogo.component'

const HeaderLogoContainer = ({ ...props }) => <HeaderLogo {...props} />

const mapStateToProps = createStructuredSelector({
	isBackgroundDark: isBackgroundDarkSelector,
})

export default connect(mapStateToProps)(HeaderLogoContainer)
