import { ActionTypes } from './drawer.types'

const INITIAL_STATE = {
	isOpen: false,
}

const drawerReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.TOGGLE_DRAWER:
			return {
				isOpen: !state.isOpen,
			}

		case ActionTypes.OPEN_DRAWER:
			return {
				isOpen: true,
			}

		case ActionTypes.CLOSE_DRAWER:
			return {
				isOpen: false,
			}

		default:
			return state
	}
}

export default drawerReducer
