import styled from 'styled-components'

import screenWidthSizes from '../../../../constants/screen-width-sizes'
import { CREAM, DARK_BLUE } from '../../../../constants/colors'

const { MOBILE, TABLET, LAPTOP } = screenWidthSizes

export const ContactWrapper = styled.div`
	height: auto;
	width: 100%;
	max-width: 450px;
	padding: 10px;
	background-color: ${CREAM};
	border-radius: 5px;
	display: flex;
	margin: 0 auto;

	@media screen and (min-width: ${MOBILE.min}px) {
		padding: 15px;
	}

	@media screen and (min-width: ${TABLET.min}px) {
		padding: 30px;
	}
`

export const VerticalText = styled.div`
	height: auto;
	width: 10%;
	margin-right: 5px;
	color: ${DARK_BLUE};
	font-weight: bold;
	font-size: 18px;
	letter-spacing: 3px;
	text-align: center;
	transform: rotate(180deg);
	writing-mode: vertical-rl;

	display: none;

	@media screen and (min-width: ${MOBILE.min}px) {
		display: block;
	}

	@media screen and (min-width: ${TABLET.min}px) {
		margin-right: 10px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		font-size: 21px;
	}
`

export const ContactLinesWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	@media screen and (min-width: ${MOBILE.min}px) {
		width: 90%;
	}
`

export const ContactLine = styled.div`
	width: 100%;
	display: flex;

	&:not(:last-child) {
		margin-bottom: 8px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		&:not(:last-child) {
			margin-bottom: 15px;
		}
	}
`

export const ImageWrapper = styled.div`
	height: auto;
	width: 20px;

	display: flex;
	align-items: center;

	margin-right: 10px;

	@media screen and (min-width: ${MOBILE.min}px) {
		margin-right: 15px;
	}

	img {
		height: auto;
		width: 100%;
	}
`

export const Text = styled.div`
	color: ${DARK_BLUE};
	text-transform: uppercase;
	font-weight: bold;
	font-size: 14px;

	display: flex;
	align-items: center;
`
