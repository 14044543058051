import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import axios from 'axios'

import { CREAM, DARK_BLUE } from '../../constants/colors'
import { API } from '../../constants/urls'

// actions
import { setBackgroundDark } from '../../redux/background/background.actions'

// styled components
const ErrorImageOverlay = styled.div`
	position: relative;
	padding: 15px;
	width: 100vw;
	height: 100vh;

	display: flex;

	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: ${DARK_BLUE};

	/* self */
	flex-grow: 1;
	flex-shrink: 0;
`

const ErrorImageContainer = styled.div`
	display: inline-block;
	background-image: ${({ imageUrl }) => `url(${imageUrl})`};
	background-size: cover;
	background-position: center;
	width: 40vh;
	height: 40vh;
`

const ErrorImageText = styled.h2`
	font-size: 28px;
	color: ${CREAM};
	text-align: center;
`

const CustomButton = styled.button`
	cursor: pointer;
	padding: 5px 25px;
	text-decoration: none;
	text-align: center;
	text-transform: uppercase;
	color: ${DARK_BLUE};
	background: ${CREAM};
	border: 1px solid #161514;
	border-radius: 5px;
	margin-top: 15px;

	&:hover {
		opacity: 0.8;
	}
`

class ErrorBoundary extends Component {
	constructor(props) {
		super(props)

		this.state = {
			error: null,
			hasErrored: false,
			sentReport: false,
		}
	}

	static getDerivedStateFromError(error) {
		return { error, hasErrored: true }
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.hasErrored !== true
	}

	async sendErrorReport() {
		if (!this.state.sentReport) {
			try {
				const { name, message, stack } = this.state.error
				await axios({
					url: `${API}/client-errors`,
					method: 'POST',
					data: {
						name,
						message,
						stack,
						state: this.props.appState,
						url: window.location.href,
					},
				})

				this.props.resetAppState()
				window.location.reload()
			} catch (postError) {
				console.error(postError) // eslint-disable-line
			} finally {
				this.setState({ sentReport: true })
			}
		} else {
			console.log('Already sent error report.') // eslint-disable-line
		}
	}

	render() {
		if (this.state.hasErrored) {
			this.props.setBackgroundDark(true)

			return (
				<ErrorImageOverlay>
					<ErrorImageContainer imageUrl={'https://i.imgur.com/oCkEbrA.png'} />
					<ErrorImageText>Something went wrong...</ErrorImageText>
					<CustomButton onClick={() => this.sendErrorReport()}>Retry</CustomButton>
				</ErrorImageOverlay>
			)
		}

		return this.props.children
	}
}

const mapStateToProps = (state) => ({
	appState: state,
})

const mapDispatchToProps = (dispatch) => ({
	setBackgroundDark: (isDark) => dispatch(setBackgroundDark(isDark)),
	resetAppState: () => dispatch({ type: 'RESET_APP_STATE' }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)
