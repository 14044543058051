import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// actions
import { closeDrawer } from '../../redux/drawer/drawer.actions'

// selectors
import { isDrawerOpenSelector } from '../../redux/drawer/drawer.selectors'
import { isBackgroundDarkSelector } from '../../redux/background/background.selectors'

import Drawer from './Drawer.component'

const DrawerContainer = ({ ...props }) => <Drawer {...props} />

const mapStateToProps = createStructuredSelector({
	isOpen: isDrawerOpenSelector,
	isBackgroundDark: isBackgroundDarkSelector,
})

const mapDispatchToProps = (dispatch) => ({
	closeDrawer: () => dispatch(closeDrawer()),
})

export default connect(mapStateToProps, mapDispatchToProps)(DrawerContainer)
