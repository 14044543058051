import React, { useState, useRef, useEffect, useCallback } from 'react'
import ReactGA from 'react-ga'

import { sendCommentToPost } from '../../api/post'

import {
	CommentFormWrapper,
	Form,
	TextInputsWrapper,
	Input,
	Textarea,
	SubmitButton,
	Row,
	PrivacyPolicy,
	StyledLink,
} from './CommentForm.styles'

const CommentForm = ({ postId }) => {
	const [state, setState] = useState({
		name: '',
		email: '',
		content: '',
		submitting: false,
		submitted: false,
	})

	const { name, email, content, submitting, submitted } = state

	const nameRef = useRef(null)
	const emailRef = useRef(null)
	const contentRef = useRef(null)

	useEffect(() => {
		if (nameRef.current) nameRef.current.value = name
	}, [name])

	useEffect(() => {
		if (emailRef.current) emailRef.current.value = email
	}, [email])

	useEffect(() => {
		if (contentRef.current) contentRef.current.value = content
	}, [content])

	const sendComment = useCallback(async () => {
		const response = await sendCommentToPost({
			author_name: name,
			author_email_address: email,
			content,
			post: postId,
		})

		if (response.status === 200) {
			setState({
				name: '',
				email: '',
				content: '',
				submitting: false,
				submitted: true,
			})
			alert('Thank you! Your comment has been submitted for approval.')
		} else {
			setState((state) => ({
				...state,
				submitting: false,
			}))
			alert('Failed to submit your comment. Please fill out all required fields.')
		}
	}, [content, email, name, postId])

	useEffect(() => {
		// if submitting and not yet submitted
		if (submitting && !submitted) {
			sendComment()
		}
	}, [submitting, submitted, sendComment])

	useEffect(() => {
		if (submitted) {
			// analytics
			ReactGA.event({
				category: 'Comment',
				action: 'Submitted',
			})
		}
	}, [submitted])

	const handleSubmit = (event) => {
		event.preventDefault()
		event.stopPropagation()

		if (!name || !email || !content) {
			return alert('Please fill out required fields.')
		}

		// if not submitting and not yet submitted
		if (!submitting && !submitted) {
			setState({
				...state,
				submitting: true,
			})
		}
	}

	const handleChange = (event) => {
		const { name, value } = event.target
		setState({
			...state,
			[name]: value,
		})
	}

	return (
		<CommentFormWrapper>
			<Form onSubmit={handleSubmit}>
				<TextInputsWrapper>
					<Input
						ref={nameRef}
						type="text"
						name="name"
						placeholder="Name"
						onBlur={handleChange}
						required
					/>
					<Input
						ref={emailRef}
						type="email"
						name="email"
						placeholder="Email Address"
						onBlur={handleChange}
						required
					/>
				</TextInputsWrapper>
				<Textarea
					ref={contentRef}
					name="content"
					placeholder="Your comment"
					onBlur={handleChange}
					required
				/>
				<Row>
					<SubmitButton dark type="submit" disabled={submitting}>
						Send
					</SubmitButton>
					<PrivacyPolicy>
						By submitting your comment, you hereby agree to our{' '}
						<StyledLink to="/privacy-policy">Privacy Policy</StyledLink>.
					</PrivacyPolicy>
				</Row>
			</Form>
		</CommentFormWrapper>
	)
}

export default CommentForm
