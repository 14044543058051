import { ActionTypes } from './featured-post.types'

const initialState = {
	item: null,
	isFetching: false,
	error: null,
}

const featuredReducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.FETCH_FEATURED_POST_START:
			return {
				...state,
				isFetching: true,
			}

		case ActionTypes.FETCH_FEATURED_POST_SUCCESS:
			return {
				...state,
				item: action.payload,
				isFetching: false,
				error: null,
			}

		case ActionTypes.FETCH_FEATURED_POST_FAILURE:
			return {
				...state,
				error: action.payload,
				isFetching: false,
			}

		default:
			return state
	}
}

export default featuredReducer
