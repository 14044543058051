import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// actions
import { fetchThoughtsStart } from '../../../../redux/thoughts/thoughts.actions'

// selectors
import {
	thoughtsItemsSelector,
	isFetchingThoughtsSelector,
	thoughtsErrorSelector,
} from '../../../../redux/thoughts/thoughts.selectors'

// contained component
import ThoughtsSection from './Thoughts.section'

const mapStateToProps = createStructuredSelector({
	items: thoughtsItemsSelector,
	isFetching: isFetchingThoughtsSelector,
	error: thoughtsErrorSelector,
})

const mapDispatchToProps = (dispatch) => ({
	fetchThoughtsStart: () => dispatch(fetchThoughtsStart()),
})

const ThoughtsContainer = ({ ...props }) => <ThoughtsSection {...props} />

export default connect(mapStateToProps, mapDispatchToProps)(ThoughtsContainer)
