import { ActionTypes } from './featured-post.types'

export const fetchFeaturedPostStart = () => ({
	type: ActionTypes.FETCH_FEATURED_POST_START,
	payload: null,
})

export const fetchFeaturedPostSuccess = (featuredPost) => ({
	type: ActionTypes.FETCH_FEATURED_POST_SUCCESS,
	payload: featuredPost,
})

export const fetchFeaturedPostFailure = (error) => ({
	type: ActionTypes.FETCH_FEATURED_POST_FAILURE,
	payload: error,
})
