import React from 'react'

// assets
import PersonImage from '../../../../assets/images/Name.png'
import EmailImage from '../../../../assets/images/Email.png'
import PhoneImage from '../../../../assets/images/Phone.png'

// styled components
import {
	ContactWrapper,
	ContactLinesWrapper,
	ContactLine,
	ImageWrapper,
	VerticalText,
	Text,
} from './Contact.styles'

const Contact = () => (
	<ContactWrapper>
		<VerticalText>CONTACT</VerticalText>
		<ContactLinesWrapper>
			<ContactLine>
				<ImageWrapper>
					<img src={EmailImage} alt="person" />
				</ImageWrapper>
				<Text>gretchen@womaninaction.ph</Text>
			</ContactLine>

			<ContactLine>
				<ImageWrapper>
					<img src={PersonImage} alt="person" />
				</ImageWrapper>
				<Text>Love Capulong</Text>
			</ContactLine>

			<ContactLine>
				<ImageWrapper>
					<img src={PhoneImage} alt="person" />
				</ImageWrapper>
				<Text>0917-873-0495</Text>
			</ContactLine>
		</ContactLinesWrapper>
	</ContactWrapper>
)

export default Contact
