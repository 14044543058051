import { HashLink } from 'react-router-hash-link'
import styled from 'styled-components'

import { CREAM, DARK_BLUE } from '../../constants/colors'
import screenWidthSizes from '../../constants/screen-width-sizes'

export const NavigationWrapper = styled.div`
	z-index: 1000;
	position: fixed;
	top: 100px;
	right: -10px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	@media screen and (max-width: ${screenWidthSizes.LAPTOP.max}px) {
		top: 80px;
		right: -40px;
	}

	@media screen and (max-width: ${screenWidthSizes.TABLET.max}px) {
		display: none;
	}
`

export const StyledLink = styled(HashLink)``

export const LinksWrapper = styled.div`
	width: auto;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	transform: rotate(-90deg);

	${StyledLink} {
		color: ${({ isBackgroundDark }) => (isBackgroundDark ? CREAM : DARK_BLUE)};

		font-size: 20px;
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: 2px;
		transition: all 0.5s ease-in-out;

		@media screen and (max-width: ${screenWidthSizes.LAPTOP.max}px) {
			font-size: 18px;
		}
	}
`
