import React, { useEffect } from 'react'
import moment from 'moment'

// constants
import { socialMediaUrls } from '../../../../constants/urls'

// components
import VisibilitySensor from 'react-visibility-sensor'
import Loader from '../../../../components/Loader/Loader.component'
import PostMarkdown from '../../../../components/PostMarkdown/PostMarkdown.component'
import Video from '../../../../components/Video/Video.component'

import {
	SectionWrapper,
	StoryWrapper,
	SectionTitle,
	StoryContentWrapper,
	StoryImageWrapper,
	StoryImage,
	StoryTitle,
	Excerpt,
	Footer,
	StyledReadMoreLink,
} from './FeaturedPost.styles'

const { DEFAULT_IMAGE_URL } = socialMediaUrls

const FeaturedPostSection = ({
	featuredPostItem,
	isFetchingFeaturedPost,
	isBackgroundDark,
	fetchFeaturedPostStart,
	setBackgroundDark,
}) => {
	useEffect(() => {
		if (!featuredPostItem) {
			fetchFeaturedPostStart()
		}
	}, [featuredPostItem, fetchFeaturedPostStart])

	return (
		<SectionWrapper id="featured">
			<VisibilitySensor
				onChange={(isVisible) => {
					if (isVisible && isBackgroundDark) setBackgroundDark(false)
				}}
			>
				<StoryWrapper>
					<SectionTitle>Featured Story Of The Week</SectionTitle>
					{isFetchingFeaturedPost ? (
						<Loader />
					) : featuredPostItem ? (
						<StoryContentWrapper>
							{featuredPostItem.images != null &&
							featuredPostItem.images.length > 0 ? (
								<StoryImageWrapper>
									{featuredPostItem.images[0].mime.startsWith('image/') ? (
										<StoryImage
											src={featuredPostItem.images[0].url}
											alt="featured image"
										/>
									) : featuredPostItem.images[0].mime.startsWith('video/') ? (
										<Video
											src={featuredPostItem.images[0].url}
											alt="gallery slide"
										/>
									) : (
										<StoryImage src={DEFAULT_IMAGE_URL} alt="featured image" />
									)}
								</StoryImageWrapper>
							) : null}

							<StoryTitle>{featuredPostItem.title}</StoryTitle>
							<Excerpt>
								{featuredPostItem.content != null &&
								featuredPostItem.content.length > 0 ? (
									featuredPostItem.content
										.substr(0, 250)
										.concat('...')
										.split('\n')
										.map((item, key) => {
											return (
												<span key={key}>
													<PostMarkdown source={item} />
													<br />
												</span>
											)
										})
								) : (
									<span>No content.</span>
								)}
							</Excerpt>
							<Footer>
								<span>{moment(featuredPostItem.createdAt).fromNow()}</span>
								<span>{featuredPostItem.author_name}</span>
							</Footer>

							<StyledReadMoreLink
								to={`/${
									featuredPostItem.category != null &&
									featuredPostItem.category.name != null &&
									featuredPostItem.category.name.length > 0
										? featuredPostItem.category.name.toLowerCase()
										: 'posts'
								}/${featuredPostItem.slug || featuredPostItem.id}`}
							>
								Read More
							</StyledReadMoreLink>
						</StoryContentWrapper>
					) : (
						<Loader />
					)}
				</StoryWrapper>
			</VisibilitySensor>
		</SectionWrapper>
	)
}

export default FeaturedPostSection
