import React from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

// actions
import { setBackgroundDark } from '../../../../redux/background/background.actions'

// selectors
import { isDrawerOpenSelector } from '../../../../redux/drawer/drawer.selectors'
import { isBackgroundDarkSelector } from '../../../../redux/background/background.selectors'

import BannerSection from './Banner.section'

const BannerSectionContainer = ({ ...props }) => <BannerSection {...props} />

const mapStateToProps = createStructuredSelector({
	isDrawerOpen: isDrawerOpenSelector,
	isBackgroundDark: isBackgroundDarkSelector,
})

const mapDispatchToProps = (dispatch) => ({
	setBackgroundDark: (isDark) => dispatch(setBackgroundDark(isDark)),
})
export default connect(mapStateToProps, mapDispatchToProps)(BannerSectionContainer)
