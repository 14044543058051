import { ActionTypes } from './drawer.types'

export const toggleDrawer = () => ({
	type: ActionTypes.TOGGLE_DRAWER,
	payload: null,
})

export const openDrawer = () => ({
	type: ActionTypes.OPEN_DRAWER,
	payload: null,
})

export const closeDrawer = () => ({
	type: ActionTypes.CLOSE_DRAWER,
	payload: null,
})
