import React from 'react'
import styled, { css } from 'styled-components'

import colors from '../../constants/colors.js'

export const customButtonStyles = css`
	cursor: pointer;
	padding: 8px 20px;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 1px;
	border: none;
	border-radius: 5px;
`
const StyledButton = styled.button`
	${customButtonStyles}
	transition: all 0.5s ease-in-out;

	background-color: ${({ dark }) => (dark ? colors.DARK_BLUE : colors.CREAM)};
	color: ${({ dark }) => (dark ? colors.CREAM : colors.DARK_BLUE)};
`

const CustomButton = ({ children, ...props }) => <StyledButton {...props}>{children}</StyledButton>

export default CustomButton
