import styled from 'styled-components'

import screenWidthSizes from '../../constants/screen-width-sizes'
import { DARK_BLUE } from '../../constants/colors'

const { TABLET, LAPTOP } = screenWidthSizes

export const FooterWrapper = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	background-color: ${DARK_BLUE};
	display: flex;
	justify-content: center;
	margin-top: auto;
`

export const Row = styled.div`
	width: 100%;
	max-width: 1100px;
	height: auto;
	display: flex;
	flex-wrap: wrap;

	@media screen and (min-width: ${TABLET.min}px) {
		padding: 15px;
	}
`

export const Column = styled.div`
	flex: 100%;
	max-width: 100%;
	padding: 15px;

	@media screen and (min-width: ${LAPTOP.min}px) {
		flex: 50%;
		max-width: 50%;

		&#links {
			order: 1;
		}

		&#contact {
			order: 2;
		}
	}
`
