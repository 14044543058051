import styled from 'styled-components'
import { Link } from 'react-router-dom'

import screenWidthSizes from '../../constants/screen-width-sizes'
import { CREAM, DARK_BLUE } from '../../constants/colors'
import { customButtonStyles } from '../../components/CustomButton/CustomButton.component'
export const { MOBILE, TABLET, LAPTOP } = screenWidthSizes

export const PostCardWrapper = styled.div`
	position: relative;
	height: 500px;
	width: 100%;
	max-width: 550px;
	border-radius: 15px;
	padding: 15px 15px 5px 15px;
	max-height: 750px;
	display: flex;
	flex-direction: column;
	justify-content: center;

	background-color: ${CREAM};

	margin: auto 5px;

	@media screen and (min-width: ${MOBILE.min}px) {
		height: 550px;
	}

	@media screen and (min-width: ${TABLET.min}px) {
		height: 600px;
		padding: 20px 20px 5px 20px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		max-width: 600px;
		padding: 30px 30px 10px 30px;
	}
`
export const PostTitle = styled.h3`
	color: ${DARK_BLUE};
	font-size: 18px;
	margin-bottom: 15px;
	text-transform: uppercase;
`

export const ImageWrapper = styled.div`
	position: relative;
	height: 200px;
	width: 100%;
	overflow: hidden;
	border-radius: 5px;
	margin-bottom: 15px;
	display: flex;
	justify-content: center;
	flex-shrink: 0;
	
	@media screen and (min-width: ${TABLET.min}px) {
		height: 300px;
	}

	/* @media screen and (min-width: ${LAPTOP.min}px) {
		height: 300px;
	} */
`

export const PostImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

export const Excerpt = styled.div`
	color: ${DARK_BLUE};
	font-size: 16px;
	margin-bottom: 15px;
	font-weight: normal;
	font-size: 16px;
	overflow: hidden;
	flex-grow: 1;

	br {
		display: block;
		content: '';
		margin-top: 12px;
	}
`

export const Footer = styled.div`
	color: ${DARK_BLUE};
	display: flex;
	justify-content: space-between;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 14px;
	margin-top: auto;

	.author {
		text-align: right;
	}
`

export const ReadMoreButton = styled(Link)`
	${customButtonStyles}
	align-self: center;
	margin-top: 5px;
	color: ${CREAM};
	background-color: ${DARK_BLUE};
	border: 2px solid ${CREAM};
	border-radius: 7px;
`
