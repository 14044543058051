import React from 'react'

// styled components
import { FooterWrapper, Row, Column } from './Footer.styles'

// sections
import Links from './sections/Links/Links.component'

// components
import Contact from './sections/Contact/Contact.component'

const Footer = () => (
	<FooterWrapper>
		<Row>
			<Column id="contact">
				<Contact />
			</Column>
			<Column id="links">
				<Links />
			</Column>
		</Row>
	</FooterWrapper>
)

export default Footer
