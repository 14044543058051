import styled from 'styled-components'

export const HomePageWrapper = styled.div`
	position: relative;

	width: 100%;
	height: 100%;
	/* scroll-snap-type: y mandatory; */
	overflow-y: scroll;
	overscroll-behavior-y: none;
`
