import React from 'react'
import { useMediaQuery } from 'react-responsive'
import VisibilitySensor from 'react-visibility-sensor'

// assets
import HomeBannerVideoDesktop from '../../../../assets/videos/Home_Banner_Video_Desktop.mp4'
import HomeBannerVideoMobile from '../../../../assets/videos/Home_Banner_Video_Mobile.mp4'
import TexturedWhiteLogo from '../../../../assets/images/Logo_White_Textured.png'
import HomeBannerPosterMobile from '../../../../assets/images/Home_Banner_Poster_Mobile.jpg'
import HomeBannerPosterDesktop from '../../../../assets/images/Home_Banner_Poster_Desktop.jpg'

// components
import CustomButton from '../../../../components/CustomButton/CustomButton.component'
import VideoPlayer from '../../../../components/VideoPlayer/VideoPlayer.component'

// styled components
import { BannerSectionWrapper, VideoPlayerWrapper, LogoWrapper, WriteLink } from './Banner.styles'

const BannerSection = ({ isDrawerOpen, isBackgroundDark, setBackgroundDark }) => {
	const isMobile = useMediaQuery({ maxWidth: 425 })
	const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

	return (
		<VisibilitySensor
			onChange={(isVisible) => {
				if (isVisible && !isBackgroundDark) setBackgroundDark(true)
			}}
		>
			<BannerSectionWrapper id="banner">
				<VideoPlayerWrapper>
					{isMobile || isPortrait ? (
						<VideoPlayer
							source={HomeBannerVideoMobile}
							poster={HomeBannerPosterMobile}
						/>
					) : (
						<VideoPlayer
							source={HomeBannerVideoDesktop}
							poster={HomeBannerPosterDesktop}
						/>
					)}
				</VideoPlayerWrapper>
				<LogoWrapper>
					<img src={TexturedWhiteLogo} alt="logo" />
				</LogoWrapper>
				<WriteLink to="/#write">
					<CustomButton dark={isDrawerOpen}>Write Your Story</CustomButton>
				</WriteLink>
			</BannerSectionWrapper>
		</VisibilitySensor>
	)
}

export default BannerSection
