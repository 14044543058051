import React from 'react'

// assets
import DarkBlueLogo from '../../assets/images/DarkBlue_Logo.png'
import CreamLogo from '../../assets/images/Cream_Logo.png'

import { StyledLink, StyledImage } from './HeaderLogo.styles'
const HeaderLogo = ({ isBackgroundDark }) => {
	return (
		<StyledLink to="/" id="header-logo">
			<StyledImage src={CreamLogo} alt="logo" show={isBackgroundDark} />
			<StyledImage src={DarkBlueLogo} alt="logo" show={!isBackgroundDark} />
		</StyledLink>
	)
}

export default HeaderLogo
