import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import drawerReducer from './drawer/drawer.reducer'
import messageBoxReducer from './message-box/reducer'
import backgroundReducer from './background/background.reducer'
import userPostsReducer from './user-posts/user-posts.reducer'
import featuredPostReducer from './featured-post/featured-post.reducer'
import formReducer from './form/form.reducer'
import thoughtsReducer from './thoughts/thoughts.reducer'
import storiesReducer from './stories/stories.reducer'

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['form', 'messageBox'],
}

const combinedReducers = combineReducers({
	drawer: drawerReducer,
	messageBox: messageBoxReducer,
	background: backgroundReducer,
	userPosts: userPostsReducer,
	featuredPost: featuredPostReducer,
	form: formReducer,
	thoughts: thoughtsReducer,
	stories: storiesReducer,
})

const rootReducer = (state, action) => {
	// when a logout action is dispatched it will reset redux state
	if (action.type === 'RESET_APP_STATE') {
		state = undefined
	}

	return combinedReducers(state, action)
}

export default persistReducer(persistConfig, rootReducer)
