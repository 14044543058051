import styled from 'styled-components'

// constants
import { CREAM, DARK_BLUE } from '../../constants/colors'
import screenWidthSizes from '../../constants/screen-width-sizes'

const { MOBILE } = screenWidthSizes

export const StyledMenuBars = styled.div`
	&::after,
	&::before,
	span {
		content: '';
		background-color: ${({ light }) => (light ? CREAM : DARK_BLUE)};
		border-radius: 3px;
		display: block;
		height: 4px;
		margin-bottom: 6px;
		transition: all 0.5s ease-in-out;

		@media screen and (min-width: ${MOBILE.min}px) {
			height: 5px;
			margin-bottom: 7px;
		}
	}
`

export const MenuIconWrapper = styled.div`
	z-index: 1000;
	position: fixed;
	top: 15px;
	right: 15px;
	width: 35px;

	&.open ${StyledMenuBars}::before {
		transform: translateY(10px) rotate(135deg);
		background-color: ${({ light }) => (light ? DARK_BLUE : CREAM)};
	}

	&.open ${StyledMenuBars}::after {
		transform: translateY(-10px) rotate(-135deg);
		background-color: ${({ light }) => (light ? DARK_BLUE : CREAM)};
	}

	&.open ${StyledMenuBars} span {
		transform: scale(0);
		background-color: ${({ light }) => (light ? DARK_BLUE : CREAM)};
	}

	@media screen and (min-width: ${MOBILE.min}px) {
		&.open ${StyledMenuBars}::before {
			transform: translateY(12px) rotate(135deg);
		}

		&.open ${StyledMenuBars}::after {
			transform: translateY(-12px) rotate(-135deg);
		}
		width: 40px;
		top: 30px;
		right: 30px;
	}
`
