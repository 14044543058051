import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// actions
import { setBackgroundDark } from '../../redux/background/background.actions'

// selectors
import { isBackgroundDarkSelector } from '../../redux/background/background.selectors'

import BlogPage from './Blog.page'

const mapStateToProps = createStructuredSelector({
	isBackgroundDark: isBackgroundDarkSelector,
})

const mapDispatchToProps = (dispatch) => ({
	setBackgroundDark: () => dispatch(setBackgroundDark()),
})

const BlogContainer = ({ ...props }) => <BlogPage {...props} />

export default connect(mapStateToProps, mapDispatchToProps)(BlogContainer)
