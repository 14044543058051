import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// actions
import {
	changeFormName,
	changeFormEmail,
	changeFormContact,
	changeFormTitle,
	changeFormContent,
	changeFormFiles,
	submitFormStart,
	resetFormState,
} from '../../redux/form/form.actions'

// selectors
import {
	formSelector,
	formNameSelector,
	formEmailAddressSelector,
	formContactSelector,
	formTitleSelector,
	formContentSelector,
	formFilesSelector,
	isFormSubmittingSelector,
} from '../../redux/form/form.selectors'

// contained component
import UserPostForm from './UserPostForm.component'

const mapStateToProps = createStructuredSelector({
	form: formSelector,
	name: formNameSelector,
	email: formEmailAddressSelector,
	contact: formContactSelector,
	title: formTitleSelector,
	content: formContentSelector,
	files: formFilesSelector,
	submitting: isFormSubmittingSelector,
})

const mapDispatchToProps = (dispatch) => ({
	changeName: (name) => dispatch(changeFormName(name)),
	changeEmail: (email) => dispatch(changeFormEmail(email)),
	changeTitle: (title) => dispatch(changeFormTitle(title)),
	changeContent: (content) => dispatch(changeFormContent(content)),
	changeFiles: (files) => dispatch(changeFormFiles(files)),
	changeContact: (contact) => dispatch(changeFormContact(contact)),
	submitFormStart: (form) => dispatch(submitFormStart(form)),
	resetFormState: () => dispatch(resetFormState()),
})

const UserPostFormContainer = ({ ...props }) => <UserPostForm {...props} />

export default connect(mapStateToProps, mapDispatchToProps)(UserPostFormContainer)
