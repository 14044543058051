import React from 'react'

import { LoaderWrapper } from './Loader.styles'
import LoaderVideo from '../../assets/images/Loader.gif'

const Loader = () => (
	<LoaderWrapper>
		<img src={LoaderVideo} alt="Loader" />
	</LoaderWrapper>
)

export default Loader
