import React from 'react'
import VisibilitySensor from 'react-visibility-sensor'

import { default as UserPostForm } from '../../../../components/UserPostForm/UserPostForm.container'

import { WriteSectionWrapper } from './Write.styles'

import { ContentWrapper, HeaderWrapper, ShareText, QuestionText } from './Write.styles'
const WriteSection = ({ isBackgroundDark, setBackgroundDark }) => {
	return (
		<WriteSectionWrapper id="write">
			<VisibilitySensor
				onChange={(isVisible) => {
					if (isVisible && !isBackgroundDark) setBackgroundDark(true)
				}}
			>
				<ContentWrapper>
					<HeaderWrapper>
						<ShareText>Share your story</ShareText>
						<QuestionText>What&apos;s your story of action today?</QuestionText>
					</HeaderWrapper>
					<UserPostForm />
				</ContentWrapper>
			</VisibilitySensor>
		</WriteSectionWrapper>
	)
}
export default WriteSection
