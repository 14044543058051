import React, { Fragment } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

// constants
import screenWidthSizes from '../../constants/screen-width-sizes'

import { default as SocialMedia } from '../SocialMedia/SocialMedia.container'
import { default as Drawer } from '../Drawer/Drawer.container.jsx'

// components
import { default as HeaderLogo } from '../HeaderLogo/HeaderLogo.container'
import { default as MenuIcon } from '../MenuIcon/MenuIcon.container'
import { default as Navigation } from '../Navigation/Navigation.container'
import { default as Messenger } from './Messenger/Messenger.container'

const FixedComponentsWrapper = styled.div`
	position: absolute;
`

const Header = () => {
	const isLaptopOrDesktop = useMediaQuery({ minWidth: screenWidthSizes.LAPTOP.min })

	return (
		<FixedComponentsWrapper id="Fixed">
			<HeaderLogo />
			{isLaptopOrDesktop ? (
				<Fragment>
					<Navigation />
					<SocialMedia />
					<Messenger />
				</Fragment>
			) : (
				<Fragment>
					<MenuIcon />
					<Drawer />
				</Fragment>
			)}
		</FixedComponentsWrapper>
	)
}

export default Header
