export const ActionTypes = {
	CHANGE_FORM_NAME: 'CHANGE_FORM_NAME_VALUE',
	CHANGE_FORM_EMAIL_ADDRESS: 'CHANGE_FORM_EMAIL_ADDRESS',
	CHANGE_FORM_CONTACT: 'CHANGE_FORM_CONTACT',
	CHANGE_FORM_TITLE: 'CHANGE_FORM_TITLE',
	CHANGE_FORM_CONTENT: 'CHANGE_FORM_CONTENT',
	CHANGE_FORM_FILES: 'CHANGE_FORM_FILES',
	SUBMIT_FORM_START: 'SUBMIT_FORM_START',
	SUBMIT_FORM_SUCCESS: 'SUBMIT_FORM_SUCCESS',
	SUBMIT_FORM_FAILURE: 'SUBMIT_FORM_FAILURE',
	RESET_FORM_STATE: 'RESET_FORM_STATE',
}
