import styled, { css } from 'styled-components'
import { HashLink } from 'react-router-hash-link'

import { CREAM, DARK_BLUE, BLUE_GREEN } from '../../constants/colors'
import screenWidthSizes from '../../constants/screen-width-sizes'

const { MOBILE, TABLET, LAPTOP } = screenWidthSizes

export const Wrapper = styled.div`
	width: 100%;
	background-color: ${DARK_BLUE};
`

export const Content = styled.div`
	font-size: 16px;
	width: 100%;
	max-width: 768px;
	margin: 0 auto;
	padding: 45px 15px 15px 15px;
	color: ${CREAM};

	@media screen and (min-width: ${MOBILE.min}px) {
		padding: 75px 15px 15px 15px;
	}
`

export const PageTitle = styled.h1`
	font-size: 20px;
	text-transform: uppercase;
	letter-spacing: 2px;
	text-align: center;
	margin-bottom: 15px;

	@media screen and (min-width: ${MOBILE.min}px) {
		font-size: 24px;
	}

	@media screen and (min-width: ${TABLET.min}px) {
		font-size: 24px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		font-size: 28px;
	}
`

export const Section = styled.div`
	&:not(:last-child) {
		margin-bottom: 15px;
	}
`

export const SectionTitle = styled.h2`
	position: relative;
	font-size: 22px;
	color: ${BLUE_GREEN};
	padding-left: 8px;
	margin-bottom: 5px;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		bottom: 7px;
		width: 2px;
		height: 18px;
		background-color: ${BLUE_GREEN};
	}
`

export const Paragraph = styled.p`
	font-size: 16px;
	margin-bottom: 15px;
`

export const List = styled.ul`
	padding-left: 30px;
`

export const LinkStyles = css`
	color: ${BLUE_GREEN};
	text-decoration: none;
	transition: all 0.25s ease-in;

	&:active,
	&:link,
	&:visited {
		color: ${BLUE_GREEN};
		text-decoration: none;
	}

	&:hover {
		opacity: 0.75;
	}
`

export const StyledLink = styled(HashLink)`
	${LinkStyles}
`
export const Anchor = styled.a`
	${LinkStyles}
`
