import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

// constants
import screenWidthSizes from '../../../../constants/screen-width-sizes'

// styled components
import {
	InfoSectionWrapper,
	HeaderImageWrapper,
	MainDetailsWrapper,
	MainDetailsText,
} from './Info.styles'

const { TABLET } = screenWidthSizes

const InfoSection = ({ setBackgroundDark }) => {
	const isMobileOrTablet = useMediaQuery({ maxWidth: TABLET.max })

	useEffect(() => {
		setBackgroundDark(!isMobileOrTablet)
	}, [setBackgroundDark, isMobileOrTablet])

	return (
		<InfoSectionWrapper>
			<HeaderImageWrapper />
			<MainDetailsWrapper>
				<MainDetailsText>
					<h3>Woman-In-Action</h3>
					Woman-In-Action is my personal motto and vision. It is a personal <i>
						motto
					</i>{' '}
					because it is a phrase that encapsulates everything I am — I live an active
					lifestyle of discovery through sports, travel and adventure. I love getting my
					hands dirty and jumping into the heat of the action.
					<br />
					<br />I believe the best way to learn and make a difference is by engaging with
					the world. Action also goes beyond the physical. To be a woman-in-action, for
					me, means living life in totality and becoming a fully developed person,
					mentally and spiritually. “Mens sana in corpore sano” — a sound mind in a sound
					body.
					<br />
					<br />
					Being a woman-in-action is also a <i>vision</i>, because it is who I strive to
					be everyday — a woman who advocates for positive change, and makes change happen
					in her community one step, one move & one action at a time.
				</MainDetailsText>
			</MainDetailsWrapper>
		</InfoSectionWrapper>
	)
}

export default InfoSection
