import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// selectors
import { isBackgroundDarkSelector } from '../../redux/background/background.selectors'

import Navigation from './Navigation.component'

const NavigationContainer = ({ ...props }) => <Navigation {...props} />

const mapStateToProps = createStructuredSelector({
	isBackgroundDark: isBackgroundDarkSelector,
})

export default connect(mapStateToProps)(NavigationContainer)
