import React from 'react'

import { NavigationWrapper, LinksWrapper, StyledLink } from './Navigation.styles'

const Navigation = ({ isBackgroundDark }) => (
	<NavigationWrapper>
		<LinksWrapper isBackgroundDark={isBackgroundDark}>
			<StyledLink to="/#feed">{"GRETCHEN'S WALL"}</StyledLink>
			<StyledLink to="/blog">Stories + Thoughts</StyledLink>
			<StyledLink to="/campaigns">Campaigns</StyledLink>
			<StyledLink to="/about">About</StyledLink>
		</LinksWrapper>
	</NavigationWrapper>
)
export default Navigation
