import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'

// constants
import { socialMediaUrls } from '../../constants/urls'
import { CREAM, DARK_BLUE } from '../../constants/colors'
import screenWidthSizes from '../../constants/screen-width-sizes'

// assets
import { ReactComponent as FacebookIcon } from '../../assets/svg/facebook.svg'
import { ReactComponent as InstagramIcon } from '../../assets/svg/instagram.svg'
import { ReactComponent as TwitterIcon } from '../../assets/svg/twitter.svg'

const { MOBILE, LAPTOP } = screenWidthSizes

// styled components
const SocialMediaWrapper = styled.div`
	/* Desktop First */
	z-index: 1000;
	position: fixed;
	bottom: 45px;
	left: 45px;
	height: auto;
	width: auto;
	display: flex;
	justify-content: center;
	align-items: center;

	.icon {
		color: ${({ isBackgroundDark }) => (isBackgroundDark ? CREAM : DARK_BLUE)};
		margin-right: 30px;
		width: 15px;
		vertical-align: bottom;
		transition: all 0.5s ease-in-out;
	}

	.insta,
	.twitter {
		width: 25px;
	}

	/* Laptop */
	@media screen and (max-width: ${LAPTOP.max}px) {
		bottom: 30px;
		left: 30px;
	}

	/* Mobile */
	@media screen and (max-width: ${MOBILE.max}px) {
		display: none;
	}
`

const HorizontalBar = styled.div`
	z-index: 1000;
	position: fixed;
	bottom: 105px;
	left: 48px;

	&::before {
		position: absolute;
		top: 12px;
		left: 5px;
		content: '';
		height: 2px;
		width: 108px;
		background: ${({ isBackgroundDark }) => (isBackgroundDark ? CREAM : DARK_BLUE)};
		transition: all 0.5s ease-in-out;
	}

	@media screen and (max-width: ${LAPTOP.max}px) {
		left: 33px;
		bottom: 90px;
	}
`

const BarStyles = css`
	z-index: 1000;
	position: fixed;
	bottom: 105px;

	&::before {
		position: absolute;
		top: 12px;
		left: 5px;
		content: '';
		height: 18px;
		width: 2px;
		background: ${({ isBackgroundDark }) => (isBackgroundDark ? CREAM : DARK_BLUE)};
		transition: all 0.5s ease-in-out;
	}

	@media screen and (max-width: ${LAPTOP.max}px) {
		bottom: 90px;
	}
`
const Bar1 = styled.div`
	${BarStyles}
	left: 47px;
	background: ${({ isBackgroundDark }) => (isBackgroundDark ? CREAM : DARK_BLUE)};

	@media screen and (max-width: ${LAPTOP.max}px) {
		left: 33px;
	}
`

const Bar2 = styled.div`
	${BarStyles}
	left: 97px;
	background: ${({ isBackgroundDark }) => (isBackgroundDark ? CREAM : DARK_BLUE)};

	@media screen and (max-width: ${LAPTOP.max}px) {
		left: 82px;
	}
`

const Bar3 = styled.div`
	${BarStyles}
	left: 154px;
	background: ${({ isBackgroundDark }) => (isBackgroundDark ? CREAM : DARK_BLUE)};

	@media screen and (max-width: ${LAPTOP.max}px) {
		left: 140px;
	}
`

const BeFeaturedText = styled.span`
	font-weight: bold;
	font-size: 14px;
	color: ${({ isBackgroundDark }) => (isBackgroundDark ? CREAM : DARK_BLUE)};
	transition: all 0.5s ease-in-out;
	letter-spacing: 3px;
	text-transform: uppercase;
`

const BeFeaturedWrapper = styled.div`
	z-index: 1000;
	position: fixed;
	bottom: 95px;
	left: 50px;
	height: auto;
	width: auto;
	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: ${LAPTOP.max}px) {
		bottom: 80px;
		left: 36px;
	}
`

const SocialMedia = ({ isBackgroundDark }) => {
	const { FACEBOOK, INSTAGRAM, TWITTER } = socialMediaUrls
	return (
		<Fragment>
			<BeFeaturedWrapper>
				<HorizontalBar isBackgroundDark={isBackgroundDark} />
				<BeFeaturedText isBackgroundDark={isBackgroundDark}>Be Featured</BeFeaturedText>
				<Bar1 isBackgroundDark={isBackgroundDark} />
				<Bar2 isBackgroundDark={isBackgroundDark} />
				<Bar3 isBackgroundDark={isBackgroundDark} />
			</BeFeaturedWrapper>
			<SocialMediaWrapper isBackgroundDark={isBackgroundDark}>
				<a href={FACEBOOK} target="_blank" rel="noopener noreferrer">
					<FacebookIcon className="icon fb" />
				</a>
				<a href={INSTAGRAM} target="_blank" rel="noopener noreferrer">
					<InstagramIcon className="icon insta" />
				</a>
				<a href={TWITTER} target="_blank" rel="noopener noreferrer">
					<TwitterIcon className="icon twitter" />
				</a>
			</SocialMediaWrapper>
		</Fragment>
	)
}

export default SocialMedia
