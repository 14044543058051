import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

// actions
import { setBackgroundDark } from '../../../../redux/background/background.actions'

// selectors
import { isBackgroundDarkSelector } from '../../../../redux/background/background.selectors'

import WriteSection from './Write.section'

const WriteSectionContainer = ({ ...props }) => <WriteSection {...props} />

const mapStateToProps = createStructuredSelector({
	isBackgroundDark: isBackgroundDarkSelector,
})

const mapDispatchToProps = (dispatch) => ({
	setBackgroundDark: (isDark) => dispatch(setBackgroundDark(isDark)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WriteSectionContainer)
