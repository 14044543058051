import styled from 'styled-components'

// constants
import { DARK_BLUE, CREAM } from '../../constants/colors'
import screenWidthSizes from '../../constants/screen-width-sizes.js'

const { SMALL_MOBILE, TABLET } = screenWidthSizes

export const NoticeWrapper = styled.div`
	z-index: 800;
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -40%);
	margin: auto;
	width: 90%;
	height: auto;
	transition: all 0.5s ease-in-out;
	opacity: 0;
	flex-shrink: 0;
	border-radius: 10px;
	box-shadow: 0px 0px 3px ${DARK_BLUE};

	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	animation-name: fadeIn;
	animation-duration: 2.5s;
	animation-delay: 1s;
	animation-timing-function: ease-in;
	animation-fill-mode: forwards;

	@media screen and (min-width: ${TABLET.min}px) {
		width: 365px;
		top: 15px;
		left: 15px;
		transform: unset;
	}
`

export const HeaderWrapper = styled.div`
	width: 100%;
	height: auto;
	padding: 10px 10px 5px 10px;
	background: ${DARK_BLUE};
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	box-shadow: 0px 0px 5px ${DARK_BLUE};
`

export const IconWrapper = styled.div`
	display: inline-block;
	width: 100%;
	padding-right: 10px;

	img {
		width: 20px;
		margin-right: 5px;
		vertical-align: middle;

		@media screen and (max-width: ${SMALL_MOBILE.max}px) {
			display: none;
		}
	}

	@media screen and (min-width: ${TABLET.min}px) {
		font-size: 16px;
	}
`

export const HeaderText = styled.h3`
	font-family: 'Titillium';
	font-weight: bold;
	letter-spacing: 1px;
	display: inline-block;
	text-transform: uppercase;
	font-size: 14px;
	color: ${CREAM};
	vertical-align: middle;
	padding-bottom: 5px;

	@media screen and (min-width: ${TABLET.min}px) {
		font-size: 16px;
	}
`

export const CloseIcon = styled.span`
	position: absolute;
	right: 10px;
	cursor: pointer;
	color: ${CREAM};
`

export const MessageBodyWrapper = styled.div`
	height: auto;
	padding: 30px;
	background: ${CREAM};
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;

	@media screen and (min-width: ${TABLET.min}px) {
		padding: 30px;
	}
`

export const MessageText = styled.p`
	font-size: 16px;
	font-weight: normal;
	text-align: left;
	color: ${DARK_BLUE};
	margin-bottom: 15px;
	line-height: 20px;

	&:last-child {
		margin-bottom: 0;
	}

	&:not(:last-child) {
		margin-bottom: 15px;
	}
`
