import styled from 'styled-components'
import { HashLink } from 'react-router-hash-link'

// constants
import { DARK_BLUE } from '../../../../constants/colors'
import screenWidthSizes from '../../../../constants/screen-width-sizes.js'

const { LAPTOP } = screenWidthSizes

export const BannerSectionWrapper = styled.div`
	position: relative;

	/* size */
	height: 100%;
	width: 100%;
	background: ${DARK_BLUE};

	flex-grow: 1;
	flex-shrink: 0;
`

export const VideoPlayerWrapper = styled.div`
	z-index: 500;
	position: absolute;
	height: 100%;
	width: 100%;

	video {
		z-index: 600;
		height: 100%;
		width: 100%;
		object-fit: cover;
	}
`

export const LogoWrapper = styled.div`
	z-index: 600;
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 250px;

		@media screen and (min-width: 768px) {
			width: 350px;
		}
	}
`

export const WriteLink = styled(HashLink)`
	z-index: 700;
	position: absolute;
	left: 15px;
	bottom: 15px;
	transition: all 0.5s ease-in-out;

	@media screen and (min-width: ${LAPTOP.min}px) {
		display: none;
	}
`
