import { ActionTypes } from './thoughts.types'

const INITIAL_STATE = {
	items: [],
	isFetching: false,
	error: null,
}

const thoughtsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.FETCH_THOUGHTS_START:
			return {
				items: [],
				isFetching: true,
				error: null,
			}

		case ActionTypes.FETCH_THOUGHTS_SUCCESS:
			return {
				items: action.payload,
				isFetching: false,
				error: null,
			}

		case ActionTypes.FETCH_THOUGHTS_FAILURE:
			return {
				items: [],
				isFetching: false,
				error: action.payload,
			}

		default:
			return state
	}
}

export default thoughtsReducer
