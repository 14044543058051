import React from 'react'

// utils
import { getTimeElapsedBetweenDates } from '../../utils/index'

// styled components
import {
	PostCardWrapper,
	PostTitle,
	ImageWrapper,
	PostImage,
	Excerpt,
	Footer,
	ReadMoreButton,
} from './PostCard.styles'

// components
import PostMarkdown from '../PostMarkdown/PostMarkdown.component'
import Video from '../Video/Video.component'

const defaultImageUrl = 'https://i.imgur.com/KV4hCpd.jpg'

const PostCard = ({ post }) => {
	const image = post.images != null && post.images.length > 0 ? post.images[0] : null
	const imageUrl = image != null && image.url != null ? image.url : defaultImageUrl

	return (
		<PostCardWrapper key={post.id}>
			<ImageWrapper>
				{image && image.mime.startsWith('image/') ? (
					<PostImage src={imageUrl} alt="featured" />
				) : image && image.mime.startsWith('video/') ? (
					<Video src={imageUrl} alt="featured" />
				) : (
					<PostImage src={imageUrl} alt="featured" />
				)}
			</ImageWrapper>

			<PostTitle>{post.title}</PostTitle>

			<Excerpt>
				{post.content != null && post.content.length > 0 ? (
					post.content
						.substr(0, 200)
						.concat('...')
						.split('\n')
						.map((item, key) => {
							return (
								<span key={key}>
									<PostMarkdown source={item} />
									<br />
								</span>
							)
						})
				) : (
					<span>No content.</span>
				)}
			</Excerpt>

			<Footer>
				<span className="date">
					{getTimeElapsedBetweenDates(post.createdAt, new Date())}
				</span>
				<span className="author">{post.author_name}</span>
			</Footer>
			<ReadMoreButton
				to={`/${post.category.name === 'Thoughts' ? 'thoughts' : 'stories'}/${
					post.slug || post.id
				}`}
			>
				READ MORE
			</ReadMoreButton>
		</PostCardWrapper>
	)
}

export default PostCard
