import styled from 'styled-components'
import { Link } from 'react-router-dom'

import screenWidthSizes from '../../../../constants/screen-width-sizes'
import { CREAM, BLUE_GREEN, DARK_BLUE } from '../../../../constants/colors'

import { customButtonStyles } from '../../../../components/CustomButton/CustomButton.component'

export const { MOBILE, TABLET, LAPTOP } = screenWidthSizes

export const UserPostsSectionWrapper = styled.div`
	padding: 15px;
	position: relative;
	background: ${BLUE_GREEN};
	background-position: center center;
	background-repeat: no-repeat;

	/* size */
	width: 100%;
	min-height: 100%;
	height: auto;

	overscroll-behavior-y: none;

	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (min-width: ${TABLET.min}px) {
		padding: 30px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		padding: 45px;
	}
`

export const SectionTitle = styled.h2`
	font-family: 'Titillium';
	font-size: 24px;
	color: ${CREAM};
	text-transform: uppercase;
	font-weight: bold;

	@media screen and (min-width: ${MOBILE.min}px) {
		font-size: 22px;
		line-height: 30px;
	}

	@media screen and (min-width: ${TABLET.min}px) {
		font-size: 28px;
		line-height: 36px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		font-size: 40px;
		line-height: 52px;
	}
`

export const UserPostWrapper = styled.div`
	position: relative;
	/* scroll-snap-align: center; */

	height: auto;
	width: 100%;
	margin: 20px 0px;
	border-radius: 15px;
	padding: 30px;

	background-color: ${CREAM};

	@media screen and (min-width: ${LAPTOP.min}px) {
		width: 768px;
		padding: 45px 60px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		padding: 60px 75px;
	}
`
export const PostTitle = styled.h3`
	color: ${DARK_BLUE};
	font-size: 18px;
	margin-bottom: 15px;
	text-transform: uppercase;
`

export const ImageWrapper = styled.div`
	position: relative;
	max-height: 250px;
	height: auto;
	width: 100%;
	overflow: hidden;
	margin-bottom: 15px;
	display: flex;
	justify-content: center;

	@media screen and (min-width: ${TABLET.min}px) {
		max-height: 350px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		max-height: 450px;
	}
`

export const PostImage = styled.img`
	width: 100%;
	height: auto;
	border-radius: 5px;
	object-fit: cover;
`

export const Content = styled.div`
	color: ${DARK_BLUE};
	font-size: 16px;
	margin-bottom: 15px;
	font-weight: normal;
	font-size: 16px;
	max-height: 200px;
	overflow: hidden;

	br {
		display: block;
		content: '';
		margin-top: 12px;
	}
`

export const Footer = styled.div`
	color: ${DARK_BLUE};
	display: flex;
	justify-content: space-between;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 14px;

	.author {
		text-align: right;
	}
`

export const ReadMoreButton = styled(Link)`
	${customButtonStyles}

	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: -20px;
	color: ${CREAM};
	background-color: ${DARK_BLUE};
	border: 2px solid ${CREAM};
	border-radius: 7px;
`
