import React from 'react'

import { Wrapper, Paragraph, StyledLink } from './Description.styles'

const DescriptionSection = () => {
	return (
		<Wrapper>
			<Paragraph>
				Know more about our cause, the story of the people we have helped and the people you
				can help at <br />
				<StyledLink to="/thoughts/you-can-be-a-hero-today">
					You Can Be A Hero Today
				</StyledLink>
			</Paragraph>
			<Paragraph>
				Sponsoring one bike, including the helmet and vest, costs 3,500 pesos.
			</Paragraph>
			<Paragraph>
				You are welcome to donate any amount you&apos;d like! If the amount of your donation
				reaches at least one bike, we will send you the art card containing the story of the
				person or people you have helped :)
			</Paragraph>
		</Wrapper>
	)
}

export default DescriptionSection
