import { takeLatest, put, call, all } from 'redux-saga/effects'

// api
import { getThoughts } from '../../api/get'

import { ActionTypes } from './thoughts.types'
import { fetchThoughtsSuccess, fetchThoughtsFailure } from './thoughts.actions'

function* fetchThoughtsAsync() {
	const response = yield call(getThoughts)
	if (response.status === 200) {
		yield put(fetchThoughtsSuccess(response.data))
	} else {
		yield put(fetchThoughtsFailure(response))
	}
}

function* onFetchThoughtsStart() {
	yield takeLatest(ActionTypes.FETCH_THOUGHTS_START, fetchThoughtsAsync)
}

export function* thoughtsSagas() {
	yield all([call(onFetchThoughtsStart)])
}
