import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

// analytics
import ReactGA from 'react-ga'

// service worker
import * as serviceWorker from './serviceWorker'

// redux store
import { store, persistor } from './redux/store'

// fonts
import './assets/fonts/Titillium/Titillium-Regular.ttf'
import './assets/fonts/Titillium/Titillium-Bold.ttf'

// styles
import './index.css'
import GlobalStyles from './global.styles'

// components
import { default as App } from './components/App/App.container'
import Loader from './components/Loader/Loader.component'

ReactGA.initialize('UA-167329499-1')
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter basename="/">
				<PersistGate persistor={persistor} loading={<Loader />}>
					<GlobalStyles />
					<App />
				</PersistGate>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
