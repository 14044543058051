import React from 'react'

import { BannerWrapper, BannerHeading, BannerText, SmallText, BicycleImage } from './Banner.styles'
import PersonRidingBicycle from '../../../../assets/images/Person_Riding_Bicycle.png'
// import { BLUE_GREEN } from '../../../../constants/colors'

const BannerSection = () => {
	return (
		<BannerWrapper>
			<BannerText>Current Campaign</BannerText>
			<BannerHeading>
				Donate A Bike,
				<br />
				Save A Job
			</BannerHeading>
			<SmallText>
				Donations are closed at the moment.
				<br />
				Thank you for your kindness.
				{/* Already donated?{' '}
				<span style={{ textDecoration: 'underline', color: BLUE_GREEN }}>
					<a href="#donation-options" style={{ color: BLUE_GREEN }}>
						Let us know!
					</a>
				</span> */}
			</SmallText>
			<BicycleImage src={PersonRidingBicycle} />
		</BannerWrapper>
	)
}

export default BannerSection
