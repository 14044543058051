const screenWidthSizes = {
	SMALL_MOBILE: {
		min: null,
		max: 320,
	},
	MOBILE: {
		min: 321,
		max: 425,
	},
	TABLET: {
		min: 426,
		max: 768,
	},
	LAPTOP: {
		min: 769,
		max: 1023,
	},

	DESKTOP: {
		min: 1024,
		max: null,
	},
}

export default screenWidthSizes
