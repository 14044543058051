import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { DARK_BLUE, CREAM, BLUE_GREEN } from '../../constants/colors'
import screenWidthSizes from '../../constants/screen-width-sizes'
import CustomButton from '../CustomButton/CustomButton.component'

const { TABLET, LAPTOP } = screenWidthSizes

export const CommentFormWrapper = styled.div`
	width: 100%;
	margin-bottom: 30px;
	display: relative;
`

export const Form = styled.form``

export const TextInputsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
`
export const Input = styled.input`
	font-size: 16px;
	color: ${DARK_BLUE};
	width: 100%;
	padding: 10px;
	color: ${CREAM};
	background: ${DARK_BLUE};
	border: none;
	border-radius: 5px;
	margin-bottom: 5px;

	&::placeholder {
		color: ${CREAM + '99'};
	}

	@media screen and (min-width: ${TABLET.min}px) {
		:last-child {
			margin-left: auto;
		}

		width: 49.5%;
	}

	@media screen and (min-width: ${TABLET.min}px) {
		padding: 10px 15px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		padding: 10px 20px;
	}
`

export const Textarea = styled.textarea`
	font-size: 16px;
	color: ${CREAM};
	background: ${DARK_BLUE};
	padding: 10px;
	height: 200px;
	width: 100%;
	border-radius: 5px;
	resize: none;

	&::placeholder {
		color: ${CREAM + '99'};
	}

	@media screen and (min-width: ${TABLET.min}px) {
		padding: 15px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		padding: 20px;
	}
`

export const Row = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`

export const SubmitButton = styled(CustomButton)`
	padding: 7px 35px;
	letter-spacing: 3px;
	font-size: 14px;
	margin-bottom: 10px;
`

export const PrivacyPolicy = styled.div`
	font-size: 14px;
	text-align: center;
	color: ${DARK_BLUE};
`

export const StyledLink = styled(Link)`
	color: ${BLUE_GREEN};
	text-decoration: underline;
`
