import React, { useEffect } from 'react'

// assets
import MessageImage from '../../assets/images/Message.png'

// styled components
import {
	NoticeWrapper,
	HeaderWrapper,
	HeaderText,
	CloseIcon,
	IconWrapper,
	MessageBodyWrapper,
	MessageText,
} from './MessageBox.styles'

const paragraph1 = `Hi! In this time of the #Covid19 crisis, we'd like to share more #happinews to the world.`
const paragraph2 = `Let’s all help uplift each other’s spirits! If you have stories to share, or just want to read about
the little blessings of everyday, feel at home!`
const paragraph3 = `Welcome :)`

const Notice = ({ isMessageBoxClosed, closeMessageBox }) => {
	useEffect(() => {
		setTimeout(() => {
			if (!isMessageBoxClosed) closeMessageBox()
		}, 15000)
	}, [isMessageBoxClosed, closeMessageBox])

	return (
		<NoticeWrapper>
			<HeaderWrapper>
				<IconWrapper>
					<img src={MessageImage} alt="message" />
					<HeaderText>A message from Gretchen</HeaderText>
				</IconWrapper>
				<CloseIcon onClick={closeMessageBox}>&#10005;</CloseIcon>
			</HeaderWrapper>
			<MessageBodyWrapper>
				<MessageText>{paragraph1}</MessageText>
				<MessageText>{paragraph2}</MessageText>
				<MessageText>{paragraph3}</MessageText>
			</MessageBodyWrapper>
		</NoticeWrapper>
	)
}

export default Notice
