import React from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

// actions
import { setBackgroundDark } from '../../../../redux/background/background.actions'

// selectors
import { isDrawerOpenSelector } from '../../../../redux/drawer/drawer.selectors'

import InfoSection from './Info.section'

const InfoSectionContainer = ({ ...props }) => <InfoSection {...props} />

const mapStateToProps = createStructuredSelector({
	isDrawerOpen: isDrawerOpenSelector,
})

const mapDispatchToProps = (dispatch) => ({
	setBackgroundDark: (isDark) => dispatch(setBackgroundDark(isDark)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InfoSectionContainer)
