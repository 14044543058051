import React from 'react'
import styled from 'styled-components'

// contants
import { CREAM, DARK_BLUE } from '../../../constants/colors'

const MessengerWrapper = styled.div`
	z-index: 1000;
	position: fixed;
	bottom: 35px;
	right: 120px;

	display: flex;
	min-width: 120px;
	width: auto;
`

const MessageWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	text-align: right;

	color: ${({ isBackgroundDark }) => (isBackgroundDark ? CREAM : DARK_BLUE)};
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 1px;
	text-transform: uppercase;
	margin-right: 10px;
	transition: all 0.5s ease-in-out;
`

const Bar = styled.div`
	position: relative;
	&::before {
		content: '';
		position: absolute;
		bottom: 12px;
		width: 30px;
		height: 2px;
		border-radius: 3px;
		background-color: ${({ isBackgroundDark }) => (isBackgroundDark ? CREAM : DARK_BLUE)};
		transition: all 0.5s ease-in-out;
	}
`
const Messenger = ({ isBackgroundDark }) => {
	return (
		<MessengerWrapper>
			<MessageWrapper isBackgroundDark={isBackgroundDark}>
				<span>Message me</span>
			</MessageWrapper>

			<Bar isBackgroundDark={isBackgroundDark} />
		</MessengerWrapper>
	)
}

export default Messenger
