import styled from 'styled-components'
import screenWidthSizes from '../../constants/screen-width-sizes'
import { DARK_BLUE, CREAM, BLUE_GREEN } from '../../constants/colors'

const { TABLET, LAPTOP } = screenWidthSizes

export const Wrapper = styled.div`
	width: 100%;
	border-radius: 5px;
	background: ${DARK_BLUE};
	padding: 10px;

	&:not(:last-child) {
		margin-bottom: 5px;
	}

	@media screen and (min-width: ${TABLET.min}px) {
		padding: 15px;
	}

	@media screen and (min-width: ${LAPTOP.min}px) {
		padding: 20px;
	}
`

export const MetaData = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
	color: ${BLUE_GREEN};
	font-size: 14px;
`

export const Author = styled.span`
	letter-spacing: 2px;
	text-transform: uppercase;
	font-weight: bold;
`

export const Date = styled.span`
	flex-shrink: 0;
`

export const Content = styled.div`
	font-size: 16px;
	color: ${CREAM};

	span:not(:last-child) {
		br {
			margin-top: 8px; /* change this to whatever height you want it */
		}
	}

	br {
		display: block; /* makes it have a width */
		content: ''; /* clears default height */
	}
`
