export const DARK_BLUE = '#13294D'
export const LESS_OPAQUE_DARK_BLUE = '#13294DDD'
export const BLUE_GREEN = '#38B4B1'
export const SCARLETT_RED = '#E5311F'
export const CREAM = '#FCFBE9'
export const YELLOW = '#DCC24B'

export default {
	DARK_BLUE,
	BLUE_GREEN,
	SCARLETT_RED,
	CREAM,
	YELLOW,
}
