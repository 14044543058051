import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

// analytics
import ReactGA from 'react-ga'

// assets
// import { ReactComponent as LinkSolidIcon } from '../../assets/svg/link-solid.svg'

// api
import { getPostById, getCommentsByPostId } from '../../api/get'

// components
import Loader from '../../components/Loader/Loader.component'
import Metadata from './sections/Metadata/Metadata.component'
import CustomButton from '../../components/CustomButton/CustomButton.component'
import { FacebookIcon, TwitterIcon, FacebookShareButton, TwitterShareButton } from 'react-share'
import PostMarkdown from '../../components/PostMarkdown/PostMarkdown.component'
import ImageGallery from '../../components/ImageGallery/ImageGallery'
import Comment from '../../components/Comment/Comment.component'
import CommentForm from '../../components/CommentForm/CommentForm.component'
import Footer from '../../components/Footer/Footer.component'

// styled components
import {
	PostPageWrapper,
	PostWrapper,
	ImageWrapper,
	ContentWrapper,
	Header,
	PostTitle,
	Content,
	Actions,
	SocialMediaShare,
	IconsWrapper,
	StyledLink,
	WriteStoryLink,
	CommentsWrapper,
	CommentsSectionTitle,
	ErrorWrapper,
	ErrorMessage,
	Row,
} from './Post.styles'

const PostPage = ({ match, fromFeed }) => {
	const { id } = match.params

	const [state, setState] = useState({
		fetching: false,
		fetched: false,
		post: null,
		error: null,
	})

	const [comments, setComments] = useState([])

	const { fetched, fetching, post, error } = state

	useEffect(() => {
		const getPost = async () => {
			setState((state) => ({
				...state,
				fetching: true,
			}))

			const response = await getPostById(id)

			const { data, status } = response

			if (status === 200 && data != null) {
				setState({
					post: data,
					fetching: false,
					fetched: true,
					error: null,
				})
			} else {
				setState({
					post: null,
					fetching: false,
					fetched: true,
					error: response,
				})
			}
		}

		if (!fetched && post == null && !fetching && !error) {
			getPost()
		}
	}, [fetched, fetching, error, id, post])

	useEffect(() => {
		const getComments = async () => {
			const response = await getCommentsByPostId(post.id)

			const { data, status } = response

			if (status === 200 && data != null) {
				setComments(data)
			}
		}

		// fetched, no error, and post is not null/undefined
		if (fetched && !error && post != null) {
			getComments()
		}
	}, [post, error, fetched])

	// google analytics
	useEffect(() => {
		if (post != null) {
			ReactGA.pageview(window.location.pathname + window.location.search)
		}
	}, [post])

	return (
		<PostPageWrapper>
			{error ? (
				<ErrorWrapper>
					<ErrorMessage>Failed to load post. Please try again later.</ErrorMessage>
					<StyledLink to="/#feed">
						<CustomButton dark>Go Back</CustomButton>
					</StyledLink>
				</ErrorWrapper>
			) : post == null && fetching ? (
				<Loader />
			) : post != null ? (
				<PostWrapper>
					<Helmet>
						<title>{post.title}</title>
					</Helmet>

					{post.images != null && post.images.length > 0 ? (
						<ImageWrapper>
							<ImageGallery images={post.images} />
						</ImageWrapper>
					) : null}

					<Metadata date={post.createdAt} category={post.category} tags={post.tags} />

					<ContentWrapper>
						<Header>
							<PostTitle>{post.title}</PostTitle>
							<span className="author">By {post.author_name}</span>
						</Header>

						<Content>
							{post.content != null && post.content.length > 0 ? (
								post.content.split('\n').map((item, key) => {
									return (
										<span key={key}>
											<PostMarkdown source={item} />
											<br />
										</span>
									)
								})
							) : (
								<span>No content.</span>
							)}
						</Content>
					</ContentWrapper>

					<Actions>
						<Row>
							<SocialMediaShare>
								<IconsWrapper>
									<FacebookShareButton
										url={`https://www.womaninaction.ph${match.url}`}
										className="icon"
									>
										<FacebookIcon className="icon" />
									</FacebookShareButton>
									<TwitterShareButton
										url={`https://www.womaninaction.ph${match.url}`}
										className="icon"
									>
										<TwitterIcon className="icon" />
									</TwitterShareButton>
									{/* <CopyLinkButton onClick={copyPostUrl}>
										<LinkSolidIcon className="copy-link" />
									</CopyLinkButton> */}
								</IconsWrapper>
							</SocialMediaShare>
							<WriteStoryLink to="/#write">
								<CustomButton dark>Share Your Story</CustomButton>
							</WriteStoryLink>
						</Row>

						<Row style={{ justifyContent: 'flex-end' }}>
							{fromFeed === true ? (
								<StyledLink to="/#feed">
									<CustomButton dark>View More Entries</CustomButton>
								</StyledLink>
							) : (
								<StyledLink to="/blog">
									<CustomButton dark>View More Blog Posts</CustomButton>
								</StyledLink>
							)}
						</Row>
					</Actions>

					<CommentsWrapper>
						<CommentsSectionTitle>Comments</CommentsSectionTitle>
						<CommentForm postId={post.id} />
						{comments != null && comments.length > 0
							? comments.map((comment) => (
									<Comment key={comment.id} comment={comment} />
							  ))
							: null}
					</CommentsWrapper>
				</PostWrapper>
			) : (
				<ErrorWrapper s>
					<ErrorMessage>Post does not exist. Sorry.</ErrorMessage>
					<StyledLink to="/">
						<CustomButton dark>Go Home</CustomButton>
					</StyledLink>
				</ErrorWrapper>
			)}
			<Footer />
		</PostPageWrapper>
	)
}

export default withRouter(PostPage)
