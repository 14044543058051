import React, { useEffect } from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import moment from 'moment'

// components
import Loader from '../../../../components/Loader/Loader.component'
import PostMarkdown from '../../../../components/PostMarkdown/PostMarkdown.component'
import Video from '../../../../components/Video/Video.component'

// styled components
import {
	UserPostsSectionWrapper,
	SectionTitle,
	UserPostWrapper,
	PostTitle,
	ImageWrapper,
	PostImage,
	Content,
	Footer,
	ReadMoreButton,
} from './UserPosts.styles'

const UserPosts = ({
	posts,
	isFetching,
	isBackgroundDark,
	fetchUserPostsStart,
	setBackgroundDark,
}) => {
	useEffect(() => {
		fetchUserPostsStart()
	}, [fetchUserPostsStart])

	return (
		<UserPostsSectionWrapper id="feed">
			<VisibilitySensor
				onChange={(isVisible) => {
					if (isVisible && isBackgroundDark) setBackgroundDark(false)
				}}
			>
				<SectionTitle>{"Gretchen's Wall"}</SectionTitle>
			</VisibilitySensor>
			{isFetching ? (
				<Loader />
			) : posts != null && posts.length > 0 ? (
				posts.map((post) => (
					<UserPostWrapper key={post.id}>
						{post.images != null && post.images.length > 0 ? (
							<ImageWrapper>
								{post.images[0].mime.startsWith('image/') ? (
									<PostImage
										src={post.images[0].url}
										loading="lazy"
										alt="featured"
									/>
								) : post.images[0].mime.startsWith('video/') ? (
									<Video src={post.images[0].url} alt="featured" />
								) : null}
							</ImageWrapper>
						) : null}
						<PostTitle>{post.title}</PostTitle>
						<Content>
							{post.content != null && post.content.length > 0 ? (
								post.content
									.substr(0, 200)
									.concat('...')
									.split('\n')
									.map((item, key) => {
										return (
											<span key={key}>
												<PostMarkdown source={item} />
												<br />
											</span>
										)
									})
							) : (
								<span>No content.</span>
							)}
						</Content>
						<Footer>
							<span className="date">{moment(post.createdAt).fromNow()}</span>
							<span className="author">{post.author_name}</span>
						</Footer>
						<ReadMoreButton to={`/posts/${post.slug || post.id}`}>
							READ MORE
						</ReadMoreButton>
					</UserPostWrapper>
				))
			) : (
				<h3>Woops... sorry, nothing to see here.</h3>
			)}
		</UserPostsSectionWrapper>
	)
}

export default UserPosts
