import React from 'react'
import styled from 'styled-components'

const MainWrapper = styled.main`
	position: relative;
	width: 100%;
	height: 100%;
`

const Main = ({ children }) => <MainWrapper>{children}</MainWrapper>

export default Main
