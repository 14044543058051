import { takeLatest, put, call, all } from 'redux-saga/effects'

// api
import { getStories } from '../../api/get'

import { ActionTypes } from './stories.types'
import { fetchStoriesSuccess, fetchStoriesFailure } from './stories.actions'

function* fetchStoriesAsync() {
	const response = yield call(getStories)
	if (response.status === 200) {
		yield put(fetchStoriesSuccess(response.data))
	} else {
		yield put(fetchStoriesFailure(response))
	}
}

function* onFetchStoriessStart() {
	yield takeLatest(ActionTypes.FETCH_STORIES_START, fetchStoriesAsync)
}

export function* storiesSagas() {
	yield all([call(onFetchStoriessStart)])
}
