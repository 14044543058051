import styled from 'styled-components'
import { HashLink as Link } from 'react-router-hash-link'

import { CREAM, DARK_BLUE } from '../../constants/colors'
import screenWidthSizes from '../../constants/screen-width-sizes'

export const DrawerWrapper = styled.div`
	z-index: 900;
	position: fixed;
	right: 0;
	width: 200px;
	height: 100vh;
	transform: translateX(100%);
	background-color: ${({ isBackgroundDark }) => (isBackgroundDark ? CREAM : DARK_BLUE)};

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: all 0.5s ease-in-out;

	&.open {
		transform: translateX(0);
	}

	@media screen and (min-width: ${screenWidthSizes.MOBILE.min}px) {
		width: 260px;
	}
`

export const StyledLink = styled(Link)``

export const LinksWrapper = styled.div`
	width: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transform: rotate(-90deg);

	${StyledLink} {
		color: ${({ isBackgroundDark }) => (isBackgroundDark ? DARK_BLUE : CREAM)};
		font-size: 20px;
		font-weight: bold;
		text-transform: uppercase;
		letter-spacing: 2px;

		@media screen and (min-width: ${screenWidthSizes.MOBILE.min}px) {
			font-size: 24px;
		}
	}
`

export const SocialMediaWrapper = styled.div`
	z-index: 1000;
	position: absolute;
	bottom: 0px;
	width: 100%;
	padding-bottom: 10px;
	display: flex;
	justify-content: left;

	a {
		display: flex;
	}

	a:first-child {
		margin-left: 10px;
	}

	.icon {
		color: ${({ isBackgroundDark }) => (isBackgroundDark ? DARK_BLUE : CREAM)};
		width: 15px;
		margin: 15px;
	}

	.insta,
	.twitter {
		width: 25px;
	}
`
