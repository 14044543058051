import React from 'react'
import styled from 'styled-components'
import screenWidthSizes from '../../constants/screen-width-sizes'

const { LAPTOP } = screenWidthSizes

const Wrapper = styled.div`
	position: relative;
	padding-bottom: 56.25% /* 16:9 */;
	padding-top: 25;
	height: 0;
`

const CustomIFrame = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	@media screen and (min-width: ${LAPTOP.min}px) {
		border-radius: 5px;
	}
`

const YoutubeEmbed = ({ videoId }) => (
	<Wrapper>
		<CustomIFrame
			src={`https://www.youtube.com/embed/${videoId}`}
			title="Featured Video"
			frameBorder="0"
		/>
	</Wrapper>
)

export default YoutubeEmbed
