import { takeLatest, call, put, all } from 'redux-saga/effects'

import { ActionTypes } from './featured-post.types'
import { fetchFeaturedPostSuccess, fetchFeaturedPostFailure } from './featured-post.actions'
import { getLatestFeaturedPost } from '../../api/get'

function* fetchFeaturedPostAsync() {
	const response = yield call(getLatestFeaturedPost)
	if (response.status === 200) {
		yield put(fetchFeaturedPostSuccess(response.data))
	} else {
		yield put(fetchFeaturedPostFailure(response))
	}
}

export function* fetchFeaturedPostStart() {
	yield takeLatest(ActionTypes.FETCH_FEATURED_POST_START, fetchFeaturedPostAsync)
}

export function* featuredPostSagas() {
	yield all([call(fetchFeaturedPostStart)])
}
