import axios from 'axios'

import { API } from '../constants/urls'

export const sendUserPost = async (formData) => {
	const response = await axios.post(`${API}/posts`, formData)
	return response
}

export const sendCommentToPost = async (data) => {
	try {
		const response = await axios.post(`${API}/comments`, data)
		return response
	} catch (error) {
		return error
	}
}

export const sendDonation = async (formElement, campaign) => {
	const data = { campaign: campaign._id }
	const formData = new FormData()
	const formElements = formElement.elements

	for (let i = 0; i < formElements.length; i++) {
		const element = formElements[i]

		if (!['submit', 'file'].includes(formElements[i].type)) {
			data[element.name] = element.value
		} else if (element.type === 'file') {
			const file = element.files[0]
			formData.append(`files.${element.name}`, file, file.name)
		}
	}

	formData.append('data', JSON.stringify(data))

	const response = await axios.post(`${API}/donations`, formData)

	return response
}
