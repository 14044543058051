import React, { useEffect, useRef, Fragment } from 'react'

import Loader from '../Loader/Loader.component'

// styled components
import {
	FormWrapper,
	Form,
	FormContentWrapper,
	TextFieldsWrapper,
	Input,
	Textarea,
	ButtonsWrapper,
	UploadButtonWrapper,
	SubmitButton,
	ResetForm,
	PrivacyPolicy,
	StyledLink,
} from './UserPostForm.styles'

function areFilesValid(files) {
	if (files && files.length) {
		if (files.length > 3) {
			alert('You can only upload up to three images or videos.')
			return false
		}

		for (let file of files) {
			const type = file['type'].split('/')[0]
			const ext = file['type'].split('/')[1]

			if (type !== 'image' && type !== 'video') {
				alert('Invalid file type.')
				return false
			}

			if (type === 'video' && ext !== 'mp4' && ext !== 'webm') {
				alert('Invalid file type.')
				return false
			}
		}

		return true
	} else {
		alert('You must upload at least one image or video')
		return false
	}
}

const UserPostForm = ({
	name,
	email,
	contact,
	title,
	content,
	files,
	submitting,
	changeName,
	changeEmail,
	changeContact,
	changeTitle,
	changeContent,
	changeFiles,
	submitFormStart,
	resetFormState,
}) => {
	const formRef = useRef(null)
	const nameRef = useRef(null)
	const emailRef = useRef(null)
	const contactRef = useRef(null)
	const titleRef = useRef(null)
	const contentRef = useRef(null)

	useEffect(() => {
		changeFiles([])
	}, [changeFiles])

	useEffect(() => {
		if (nameRef.current) nameRef.current.value = name
	}, [name, email, title, content, contact])

	useEffect(() => {
		if (emailRef.current) emailRef.current.value = email
	}, [email])

	useEffect(() => {
		if (titleRef.current) titleRef.current.value = title
	}, [title])

	useEffect(() => {
		if (contentRef.current) contentRef.current.value = content
	}, [content])

	useEffect(() => {
		if (contactRef.current) contactRef.current.value = contact
	}, [contact])

	const handleFormSubmit = (event) => {
		event.preventDefault()
		event.stopPropagation()

		if (files && files.length === 0) {
			alert('You must upload at least one image or video.')
			return
		}

		submitFormStart(formRef.current)
	}

	const handleFileChange = (event) => {
		const { files } = event.target
		if (areFilesValid(files)) {
			changeFiles(files)
		} else {
			changeFiles([])
		}
	}

	return (
		<FormWrapper>
			{submitting ? (
				<Fragment>
					<Loader />
					<ResetForm onClick={resetFormState}>
						Taking a while? Tap here to reset form.
					</ResetForm>
				</Fragment>
			) : (
				<Form ref={formRef} onSubmit={handleFormSubmit}>
					<FormContentWrapper>
						<TextFieldsWrapper>
							<Input
								ref={nameRef}
								name="name"
								type="text"
								onBlur={(event) => changeName(event.target.value)}
								placeholder="Name"
								required
							/>
							<Input
								ref={titleRef}
								name="title"
								type="text"
								onBlur={(event) => changeTitle(event.target.value)}
								placeholder="Title"
								required
							/>
							<Input
								ref={emailRef}
								name="email"
								type="email"
								onBlur={(event) => changeEmail(event.target.value)}
								placeholder="Email"
								required
							/>
							<Input
								ref={contactRef}
								name="contact"
								type="text"
								onBlur={(event) => changeContact(event.target.value)}
								placeholder="Contact # (Optional)"
							/>
						</TextFieldsWrapper>
						<Textarea
							ref={contentRef}
							name="content"
							onBlur={(event) => changeContent(event.target.value)}
							placeholder="What's your story?"
							required
						/>
						<ButtonsWrapper>
							<UploadButtonWrapper dark>
								<label className="custom-file-upload">
									<input
										name="images"
										type="file"
										onChange={handleFileChange}
										accept={'image/*, video/mp4, video/webm'}
										multiple
									/>
									{files && files.length > 0
										? `${files.length} Image${files.length > 1 ? 's' : ''}`
										: 'Upload Images'}
								</label>
							</UploadButtonWrapper>
							<SubmitButton type="submit">Submit</SubmitButton>
						</ButtonsWrapper>
						<PrivacyPolicy>
							By submitting your story, you hereby agree to our{' '}
							<StyledLink to="/privacy-policy">Privacy Policy</StyledLink>.
						</PrivacyPolicy>
					</FormContentWrapper>
				</Form>
			)}
		</FormWrapper>
	)
}

export default UserPostForm
