import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import App from './App.component'

// selectors
import { isMessageBoxClosedSelector } from '../../redux/message-box/selectors'

const mapStateToProps = createStructuredSelector({
	isMessageBoxClosed: isMessageBoxClosedSelector,
})

const AppContainer = ({ ...props }) => <App {...props} />

export default connect(mapStateToProps)(AppContainer)
